import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-are-you-sure',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ],
  template: `
    <div>
        <h2 mat-dialog-title style="padding:0">Are You Sure</h2>
    </div>
    <mat-dialog-content class="mat-typography" style="display: inherit">
        <span class="are-you-sure-span">{{data.text}}</span>
        <div style="display: inline-flex; width: 100%; justify-content: flex-end;">
            <button
                    mat-flat-button
                    class="btn-default"
                    style="font-size: medium; width: 47%; margin-right: 6%;"
                    (click)="yes()"
            >
                {{data.yesText}}
            </button>
            <button
                    mat-flat-button
                    class="btn-default"
                    style="font-size: medium; width: 47%; padding-left: 10px;"
                    (click)="no()"
            >
                {{data.noText}}

            </button>
        </div>
    </mat-dialog-content>
  `
})
export class AreYouSureComponent {
  functions;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

  }

  yes() {
    this.functions.yes();
  }

  no() {
    this.functions.no();
  }
}
