import * as lessonActions from './lesson.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    lesson: any;
    allLessons: any;
    lessonStep: any;
    allLessonSteps: any;
    selectedId: string;
    courseId: string;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    lesson: null,
    allLessons: null,
    lessonStep: null,
    allLessonSteps: [],
    selectedId: null,
    courseId: null
};

export function reducer(state = initialState, action: lessonActions.LessonActions): State {
    switch (action.type) {
        case lessonActions.LessonActionTypes.GetLesson: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case lessonActions.LessonActionTypes.GetLessonSuccess: {
            let lessons = state.allLessons !== null ?
             [...state.allLessons, ...[action.payload]] : [action.payload];
            return {
                ...state,
                lesson: action.payload,
                allLessons: lessons,
                error: null
            };
        }
        case lessonActions.LessonActionTypes.GetLessonFail: {
            return {
                ...state,
                lesson: null,
                error: action.payload
            };
        }
        case lessonActions.LessonActionTypes.ClearAllLessons: {
            return {
                ...state,
                pending: false,
                succeeded: false,
                error: null,
                allLessonSteps: null,
                courseId: null
            };
        }
        case lessonActions.LessonActionTypes.GetAllLessons: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case lessonActions.LessonActionTypes.GetAllLessonsSuccess: {
            return {
                ...state,
                allLessons: action.payload.items,
                courseId: action.payload.id,
                error: null
            };
        }
        case lessonActions.LessonActionTypes.GetAllLessonsFail: {
            return {
                ...state,
                allLessons: null,
                error: action.payload
            };
        }
        case lessonActions.LessonActionTypes.GetFirstLessonStep: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case lessonActions.LessonActionTypes.GetFirstLessonStepSuccess: {
            return {
                ...state,
                lessonStep: action.payload,
                error: null
            };
        }
        case lessonActions.LessonActionTypes.GetFirstLessonStepFail: {
            return {
                ...state,
                lessonStep: null,
                error: action.payload
            };
        }
        case lessonActions.LessonActionTypes.GetAllLessonSteps: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }

        case lessonActions.LessonActionTypes.GetAllLessonStepsSuccess: {
            return {
                ...state,
                allLessonSteps: action.payload.items,
                courseId: action.payload.id,
                error: null
            };
        }
        case lessonActions.LessonActionTypes.GetAllLessonStepsFail: {
            return {
                ...state,
                allLessonSteps: null,
                error: action.payload
            };
        }

        case lessonActions.LessonActionTypes.DeleteLesson: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }

        case lessonActions.LessonActionTypes.DeleteLessonSuccess: {
            let allLessonSteps = JSON.parse(JSON.stringify(state.allLessonSteps));
            let allLessons = JSON.parse(JSON.stringify(state.allLessons));
            const index1 = allLessonSteps.findIndex(item => item._id === action.payload);
            const index2 = allLessons.findIndex(item => item._id === action.payload);
            if (index1 > -1) { allLessonSteps.splice(index1, 1); }
            if (index2 > -1) { allLessons.splice(index2, 1); }

            return {
                ...state,
                allLessonSteps,
                allLessons,
                error: null
            };
        }

        case lessonActions.LessonActionTypes.CopyLesson: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }

        case lessonActions.LessonActionTypes.CopyLessonSuccess: {
            let newLessonStep = JSON.parse(JSON.stringify(action.payload));
            newLessonStep.lessonPlanStepCount = newLessonStep.lessonPlanSteps.length;

            return {
                ...state,
                allLessonSteps: [...state.allLessonSteps, ...[newLessonStep]],
                allLessons: [...state.allLessons, ...[action.payload]],
                error: null
            };
        }

        case lessonActions.LessonActionTypes.UpdateLesson: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }

        case lessonActions.LessonActionTypes.UpdateLessonSuccess: {
            let allLessons = JSON.parse(JSON.stringify(state.allLessons));
            const index1 = allLessons.findIndex(item => item._id === action.payload._id);
            if (index1 > -1) { allLessons[index1] = action.payload; }

            let allLessonSteps = JSON.parse(JSON.stringify(state.allLessonSteps)) || [];
            const index2 = allLessonSteps.findIndex(item => item._id === action.payload._id);
            if (index2 > -1) { allLessonSteps[index2] = action.payload; }

            return {
                ...state,
                allLessons,
                allLessonSteps,
                error: null
            };
        }

        case lessonActions.LessonActionTypes.CreateLesson: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }

        case lessonActions.LessonActionTypes.CreateLessonSuccess: {
            let newLessonStep = JSON.parse(JSON.stringify(action.payload));
            newLessonStep.lessonPlanStepCount = newLessonStep.lessonPlanSteps.length;

            return {
                ...state,
                allLessonSteps: [...state.allLessonSteps, ...[newLessonStep]],
                allLessons: [...state.allLessons, ...[action.payload]],
                error: null
            };
        }

        case lessonActions.LessonActionTypes.AppendLessonStepToStore: {
            let allLessons = JSON.parse(JSON.stringify(state.allLessons));
            const index = allLessons.findIndex(lesson => lesson._id === action.payload.lessonId);
            if (index > -1) {
                allLessons[index].lessonPlanSteps.push(action.payload.lessonStepId);
            }
            return {
                ...state,
                allLessonSteps: allLessons,
                allLessons: allLessons,
                error: null
            };
        }

        case lessonActions.LessonActionTypes.RemoveLessonStepFromStore: {
            let allLessons = JSON.parse(JSON.stringify(state.allLessons));
            const index = allLessons.findIndex(lesson => lesson._id === action.payload.lessonId);
            if (index > -1) {
                const stepIndex = allLessons[index].lessonPlanSteps.indexOf(action.payload.lessonStepId);
                allLessons[index].lessonPlanSteps.splice(stepIndex, 1);
            }
            return {
                ...state,
                allLessonSteps: allLessons,
                allLessons: allLessons,
                error: null
            };
        }

        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getLesson = (state: State) => state.lesson;
export const GetAllLessons = (state: State) => state.allLessons;
export const getFirstLessonStep = (state: State) => state.lessonStep;
export const getAllLessonSteps = (state: State) => state.allLessonSteps;
