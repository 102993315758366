import { UpdateLessonStep, CreateLessonStep, CreateLessonStepSuccess, DeleteLessonStep, DeleteLessonStepSuccess, EditGetAllLessonSteps, GetLessonStep, ClearLessonStep, UpdateLessonStepSuccess, EditGetAllLessonStepsSuccess } from './../../core/store/edit-lesson/edit-lesson.actions';
import { CreateLessonSuccess, GetAllLessons, UpdateLesson, UpdateLessonSuccess } from './../../core/store/lesson/lesson.actions';
import { Store } from '@ngrx/store';
import { Component, Inject, OnDestroy, OnChanges, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ViewChildren, QueryList } from '@angular/core';
import {LessonPlansStepsService} from '../../core/services/lesson-plans-steps.service';
import {LessonPlansService} from '../../core/services/lesson-plans.service';
import {ActivatedRoute, Router} from '@angular/router';
import {concatMap, map, switchMap, take, takeUntil, takeWhile, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {ActivityTypeService} from '../../core/services/activity-type.service';
import {CourseWorkbookService} from '../../core/services/course-workbook.service';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {SettingsService} from '../../core/services/new-settings.service';
import {UserService} from '../../core/services/user.service';
import {WorkbooksService} from '../../core/services/workbooks.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UtilityService} from '../../core/services/utility.service';
import {DialogData} from '../../activities/timed-reading/timed-reading.component';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {Observer} from 'rxjs/internal/types';
import { TutorialDialogComponent } from '../../core/components/support/support.component';
import { _variable_images } from '../../environments/environment';
import { ListenerService } from '../../core/services/listener.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from '../../core/services/data.service';
import {
  getCurrentSetting,
   isSetCourseLoaded,
    selectGetAllCourseWorkbook,
     selectGetAllLessonEditedSteps,
     selectGetAllLessons,
     selectGetLessonPlan,
      selectGetNonOrSubscribedCoursesById,
       selectGetPostResponseByWorkbookId } from '../../core/store';
       import * as CourseWorkbookActions from '../../core/store/course-workbook/course-workbook.actions';
import { PostWords } from '../../core/store/words/words.actions';
import { GetPlan } from '../../core/store/plan/plan.actions';
import { SetCurriculumCourse } from '../../core/store/set-course/set-course.actions';
import { AsyncPipe } from '@angular/common';
import { CourseWorkbookEditComponent, TilesUpdatingOptionEnum, WordsUpdatingOptionEnum } from '../../core/components/course-workbook-edit/course-workbook-edit.component';
import { WorkbookActivityLessonStepService } from '../../core/services/workbook-activity-lesson-step.service';
import { ClearActivityInitData, UpdateInitDataCourseWorkbookActivitySuccess } from '../../core/store/activity/activity.actions';
@Component({
  selector: 'app-edit-lesson-plans',
  templateUrl: './html/edit-lesson-plan.html',
  styleUrls: [
    './edit-lesson-plan.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EditLessonPlanComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('lessonNameInput') lessonNameInput: ElementRef;
  @ViewChild('lessonDescriptionInput') lessonDescriptionInput: ElementRef;
  public lessonPlan: any = null;
  public lessonPlanSteps: any = [];
  public activityTypes: any = [];
  public courseWorkbooks: any = [];
  public workbooks: any[];
  public userSettings: any = null;
  public lessonUrl: any;

  private lessonPlanSubject: any = new BehaviorSubject<any>(this.lessonPlan);
  private lessonPlanStepsSubject: any = new BehaviorSubject<any>(this.lessonPlanSteps);
  private activityTypesSubject: any = new BehaviorSubject<any>(this.activityTypes);
  public courseWorkbooksSubject: any = new BehaviorSubject<any>(this.courseWorkbooks);
  private workbooksSubject: any;
  private userSettingsSubject: any = new BehaviorSubject<any>(this.userSettings);
  private lessonUrlSubject: any;
  private isLoadingSubject = new BehaviorSubject<boolean>(true);

  public lessonPlan$: any = this.lessonPlanSubject.asObservable();
  public lessonPlanSteps$: any = this.lessonPlanStepsSubject.asObservable();
  public activityTypes$: any = this.activityTypesSubject.asObservable();
  public courseWorkbooks$: any = this.courseWorkbooksSubject.asObservable();
  public workbooks$: any;
  public userSettings$: any = this.userSettingsSubject.asObservable();
  public lessonUrl$: any;
  isNew = false;
  todaysDate = moment().format('LLLL');
  courseId: string;
  public updatedLessonPlanSteps: any = {};
  isLoading$ = this.isLoadingSubject.asObservable();
  hasDuplicateName = false;
  workbookLessons: any;
  displayMoreWordsText: boolean;
  @ViewChildren(CourseWorkbookEditComponent) courseWorkbookEdits: QueryList<CourseWorkbookEditComponent>;

  set setCourseWorkbooks(courseWorkbooks: any) {
    if (courseWorkbooks.length > 0) {
      this.courseWorkbooks = courseWorkbooks.sort((a, b) => a.order - b.order);
      this.courseWorkbooksSubject.next(courseWorkbooks);
    }
  }
  set setWorkbooks(workbooks: any) {
    if (workbooks !== null && workbooks.length > 0) {
      this.workbooks = workbooks;
      this.courseWorkbooks = this.courseWorkbooks
      .map(courseWorkbook => {
        courseWorkbook.workbook = this.workbooks.find(workbook => workbook._id === courseWorkbook.workbookId);
        return courseWorkbook;
      });

    }
  }

  set setLessonPlanSteps(lessonPlanSteps: any) {
    if ((lessonPlanSteps !== null && this.courseWorkbooks !== undefined) && lessonPlanSteps.length > 0 && this.courseWorkbooks.length > 0) {
      lessonPlanSteps.forEach((lessonPlanStep, i) => {
        if (!(lessonPlanStep.wordUpdating && lessonPlanStep.tileUpdating)) {
          lessonPlanStep.wordUpdating = WordsUpdatingOptionEnum.Manual;
          lessonPlanStep.tileUpdating = TilesUpdatingOptionEnum.Manual;
        }
        // if (lessonPlanStep && !lessonPlanStep.ignoreWhizzimap) {
        //   lessonPlanStep.ignoreWhizzimap = true;
        // }
      });
      this.lessonPlanStepsSubject.next(lessonPlanSteps);
      this.lessonPlanWorkbooks = [
        ...new Set(lessonPlanSteps.map(lessonPlanStep => {
          const courseWorkbook = this.courseWorkbooks.find(cw => cw._id === lessonPlanStep.courseWorkbookId);
          return courseWorkbook ? courseWorkbook.workbook.name : '';
        }))
      ];
  }
  }

  faPlusCircle = faPlusCircle;
  subscriptions = new Subscription();
  errors: any = {};
  lessonPlanWorkbooks: any[] = [];
  helpIconImage = '';
  public isSaving = false;

  public maxlengthName: boolean;

  lessonNameForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.maxLength(60)]),
      notes: new UntypedFormControl('', [Validators.maxLength(160)])

  });

  showLessonPlanNote = false;
  showLessonPlantext = false;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private lessonPlanStepsService: LessonPlansStepsService,
    private lessonPlanService: LessonPlansService,
    private activityTypeService: ActivityTypeService,
    private courseWorkbookService: CourseWorkbookService,
    private workbooksService: WorkbooksService,
    private settingsService: SettingsService,
    private userService: UserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private utilityService: UtilityService,
    private router: Router,
    private listenerService: ListenerService,
    private dataService: DataService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private async: AsyncPipe,
    private workbookActivityLessonStepService: WorkbookActivityLessonStepService
  ) {
    // this.store.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(this.route.url['value'][2].path));
    const courseId = this.activatedRoute.snapshot.params['courseId'];
    this.store.dispatch(new GetAllLessons(courseId));
    this.subscriptions.add(
      this.lessonPlan$.subscribe(lessonPlan => this.lessonPlan = lessonPlan)
    );

    this.lessonNameForm.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(values => {
        let lessonPlan: any = JSON.parse(JSON.stringify(this.async.transform(this.lessonPlan$)));
        lessonPlan.name = values.name
        lessonPlan.notes = values.notes
        this.lessonPlanSubject.next(lessonPlan)
      })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onkeyName(event: any): void {
    const name = this.lessonNameForm.get('name');
    const lessonPlanId = this.route.url['value'][1].path;
    this.maxlengthName = name.status === "INVALID"  ? true : false;
    const exist = !!this.workbookLessons?.find(lesson => {
      if (lessonPlanId === 'new') {
        return lesson.name === name.value;
      } else {
        return lesson.name === name.value && (lesson._id !== this.lessonPlan._id);
      }
    });
    this.hasDuplicateName = exist;

    let lessonPlan: any = this.async.transform(this.lessonPlan$);
    this.lessonPlanSubject.next({...lessonPlan, name: name.value});
}

  ngOnInit(): void {
    this.store.select(selectGetAllLessons)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookLessons => {
        this.workbookLessons = workbookLessons;
      });
    this.subscriptions.add(
      this.dataService._variable_images.subscribe(path => {
        this.helpIconImage = path + 'help-header.png';
      })
    );
    const lessonPlanId = this.route.url['value'][1].path;
    if (lessonPlanId !== 'new') {
      this.store.dispatch(new EditGetAllLessonSteps(lessonPlanId));
    }

    this.courseId = this.route.url['value'][2].path;
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    this.lessonPlanSubject.next({
      name: '',
      notes: '',
      courseId: this.courseId,
      order: parseInt(this.route.url['value'][3].path, 10),
      ownerKey: userId
    });
    // this.helpIconImage = _variable_images + 'help-header.png';
    this.subscriptions.add(
      this.store.select(getCurrentSetting)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userSettings => {
        this.userSettings = JSON.parse(JSON.stringify(userSettings));
        if (!this.userSettings.blackboard.wordNum) {
          this.userSettings.blackboard.wordNum = 15;
        }
        this.userSettingsSubject.next(this.userSettings);
      })
    );

    this.subscriptions.add(
      this.lessonPlanSteps$.pipe(takeUntil(this.unsubscribe$))
      .pipe(map((lessonPlanSteps: any[]) => {
        lessonPlanSteps.forEach(step => {
          if (!step.wordUpdating) {
            step.wordUpdating = WordsUpdatingOptionEnum.Manual;
          }
          if (!step.tileUpdating) {
            step.tileUpdating = TilesUpdatingOptionEnum.Manual;
          }
        });
        return lessonPlanSteps;
      }))
      .subscribe(lessonPlanSteps => {
        if (!lessonPlanSteps) {
          return;
        }
        this.lessonPlanWorkbooks = [
          ...new Set(lessonPlanSteps?.map(lessonPlanStep => {
            const courseWorkbook = this.courseWorkbooks.find(cw => cw._id === lessonPlanStep.courseWorkbookId);
            return courseWorkbook ? courseWorkbook.workbook.name : '';
          }))
        ];
      })
    );

    this.subscriptions.add(
      this.activityTypeService
      .getAll()
      .pipe(
        switchMap((activityTypes: any[]) => {
          this.activityTypesSubject.next(activityTypes);
          return this.store.select(selectGetAllCourseWorkbook);
        }),
        switchMap((courseWorkbooksRes: any) => {
          let courseWorkbooks = JSON.parse(JSON.stringify(courseWorkbooksRes));
          const ids = courseWorkbooks.map(courseWorkbook => courseWorkbook.workbookId).join(',');
          this.setCourseWorkbooks = courseWorkbooks;
          return this.workbooksService.getManyByIds(ids);
        }),
        switchMap((workbooks: any[]) => {
          this.setWorkbooks = workbooks;

          if (lessonPlanId === 'new') {
            return new Observable((observer => observer.next(this.lessonPlan)));
          }

          // return this.lessonPlanService.get(lessonPlanId);
          return this.store.select(selectGetLessonPlan, { id: lessonPlanId});
        }),
        switchMap((lessonPlan: any) => {
          if (lessonPlan && lessonPlan !== undefined) {
            this.lessonPlanSubject.next(lessonPlan);
            this.lessonNameForm.patchValue({
              name: lessonPlan.name,
              notes: lessonPlan.notes
            })
          }

          if (lessonPlanId === 'new') {
            this.createNewStep();
            return new Observable((observer => observer.next(this.lessonPlanSteps)));
          }

          // return this.lessonPlanStepsService.getAll(lessonPlanId);
          return this.store.select(selectGetAllLessonEditedSteps);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((lessonPlanStepsRes: any[]) => {
        // this.setLessonPlanSteps = JSON.parse(JSON.stringify(lessonPlanSteps));
        this.isLoadingSubject.next(false);

      })



    );

    if (lessonPlanId === 'new') {
      this.isNew = true;
      return;
    }

    this.store.dispatch(new GetPlan(JSON.parse(localStorage.profile).user_metadata.cusID));


    const courseId = this.activatedRoute.snapshot.params['courseId'];
    combineLatest([
      this.store.select(isSetCourseLoaded),
      this.store.select(selectGetNonOrSubscribedCoursesById, {id: courseId})

    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([isSetCourseLoaded, course]) => {
      if (!isSetCourseLoaded && course) {
        this.store.dispatch(new SetCurriculumCourse({
          course: course,
          redirect: false
        }));
      }
    });

    this.fixLessonPlanStepsData();
  }
  async ngOnChanges(changes) {
  }
  sortBy(prop: string): any[] {

    return this.lessonPlanSteps.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }

      if (a[prop] > b[prop]) {
        return 1;
      }

      return 0;
    });
  }

  openTutorialDialog(): void {
    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      data: {
        title: 'Lesson Plan',
        link:  'https://videos.sproutvideo.com/embed/ea9ddfb61e1ce1c763/94b4688cbac727a4'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  createNewStep() {
    if (this.courseWorkbooks.length === 0) {
      return this.openNoCourseWorkbookDialog();
    }
    let activityTypes: any[] = this.async.transform(this.activityTypes$);
    let lessonPlanSteps: any[] = this.lessonPlanSteps?.length > 0 ? this.lessonPlanSteps : this.async.transform(this.lessonPlanSteps$);


    let courseWorkbook = this.courseWorkbooks[0];

    let newStep: any = {
      _id: this.utilityService.randomString(24),
      isNewStep: true,
      name: '',
      numWords: this.userSettings.blackboard.wordNum || 15,
      ignoreWhizzimap: true,
      activityName: activityTypes[0].name,
      activityId: activityTypes[0]._id,
      courseId: this.courseId,
      ownerKey: JSON.parse(localStorage.profile).user_metadata.uid,
      order: lessonPlanSteps.length === 0 ? 0 : lessonPlanSteps[lessonPlanSteps.length - 1].order + 1,
      // words: this.courseWorkbooks[0].workbook.preview.slice(0, this.userSettings.blackboard.wordNum),
      tiles: courseWorkbook.workbook.tiles,
      courseWorkbookId: courseWorkbook._id,
      workbookId: courseWorkbook.workbookId,
      wordUpdating: WordsUpdatingOptionEnum.Manual,
      tileUpdating: TilesUpdatingOptionEnum.Manual,
      phrases: [
        {
          _id: '0',
          phrase: 'No Sentence Parts in Lesson Plan',
        }
      ],
      sentences: [
        {
          _id: '0',
          sentence: 'No Sentences in Lesson Plan',
        }
      ],
      passages: [
        {
          _id: '0',
          passage: 'No Passages in Lesson Plan',
        }
      ],
      files: [
        {
          _id: '0',
          file: 'No Files in Lesson Plan',
        }
      ]
    };

    // // Merge conflicts
    newStep.words = this.courseWorkbooks[0].workbook.preview.slice(0, this.userSettings.blackboard.wordNum);

    if (this.lessonPlan._id) {
      this.workbookActivityLessonStepService.fixActivityOrStepWords(newStep, courseWorkbook)
      .pipe(take(1))
      .subscribe(words => {
        newStep.words = words;
        newStep.numWords = words.length;
        // lessonPlanSteps.push(newStep);
        //
        // this.lessonPlanSteps = lessonPlanSteps;

        //
        this.updatedLessonPlanSteps[newStep._id] = newStep;
        // this.lessonPlanStepsSubject.next(lessonPlanSteps);
        this.save(false, false);
      });
    } else {
      // lessonPlanSteps.push(newStep);
      // this.lessonPlanSteps = lessonPlanSteps;
      this.updatedLessonPlanSteps[newStep._id] = newStep;
      // this.lessonPlanStepsSubject.next(lessonPlanSteps);
      lessonPlanSteps.push(newStep);
      this.lessonPlanSteps = lessonPlanSteps;
      this.updatedLessonPlanSteps[newStep._id] = newStep;
      this.lessonPlanStepsSubject.next(lessonPlanSteps);
    }


  }

  changeLessonPlanSteps(lessonPlanStep: any) {
    // return;
    let lessonPlanSteps: any[] = this.lessonPlanSteps?.length > 0 ? this.lessonPlanSteps : this.async.transform(this.lessonPlanSteps$);
    const tempLessonPlanSteps = lessonPlanSteps.map((lesson) => {
      if (lesson._id === lessonPlanStep._id) {
        lesson = lessonPlanStep;
        this.updatedLessonPlanSteps[lesson._id] = lesson;
      }
      return lesson;
    });
    this.lessonPlanSteps = tempLessonPlanSteps;

    // this.lessonPlanStepsSubject.next(lessonPlanSteps)
    // this.setLessonPlanSteps = lessonPlanSteps;
    // this.lessonPlanWorkbooks = [
    //   ...new Set(lessonPlanSteps.map(lessonPlanStep => {
    //     const courseWorkbook = this.courseWorkbooks.find(cw => cw._id === lessonPlanStep.courseWorkbookId);
    //     return courseWorkbook ? courseWorkbook.workbook.name : '';
    //   }))
    // ];
    this.updateLessonPlanSteps();
  }
  savingStart(save: boolean): void {
    this.isSaving = save;
    this.listenerService.callback('saving:starting');
  }

  savingEnd(event) {
    this.lessonPlanSteps = event;
    this.save(this.isSaving);
  }

  copyStep(lessonPlanStep: any) {
    let lessonPlanSteps: any[] = this.lessonPlanSteps?.length > 0 ? this.lessonPlanSteps : this.async.transform(this.lessonPlanSteps$);
    lessonPlanSteps = JSON.parse(JSON.stringify(lessonPlanSteps))

    const lessonPlanStepFromSteps = lessonPlanSteps.filter(lesson => lesson._id === lessonPlanStep._id)[0];
    const stepCopy = {
      ...lessonPlanStep,
      ...{
        order: lessonPlanStepFromSteps.order + 1,
        name: lessonPlanStep.name ? `${lessonPlanStep.name} (copy)` : '',
        _id: this.utilityService.randomString(24),
        isNewStep: true
      }
    };

    lessonPlanSteps.map((lesson, index) => {
      if (lesson.order > lessonPlanStepFromSteps.order) {// lessonPlanStep.order
        lesson.order = lesson.order + 1;
        this.updatedLessonPlanSteps[lesson._id] = lesson;
      }
      return lesson;
    });
    lessonPlanSteps.splice(stepCopy.order, 0, stepCopy);
    this.updatedLessonPlanSteps[stepCopy._id] = stepCopy;
    // this.lessonPlanSteps = lessonPlanSteps;
    // this.lessonPlanStepsSubject.next(lessonPlanSteps);
    this.save(false, false)
  }

  move($event: any) {
    let lessonPlanSteps: any = this.lessonPlanSteps?.length > 0 ? JSON.parse(JSON.stringify(this.lessonPlanSteps)) : JSON.parse(JSON.stringify(this.async.transform(this.lessonPlanSteps$)));
    if (($event.isUp && $event.index === 0) || (!$event.isUp && $event.index === lessonPlanSteps.length - 1)) {
      return;
    }

    const replacedStep = $event.isUp ? lessonPlanSteps[$event.index - 1] : lessonPlanSteps[$event.index + 1]
    replacedStep.order = $event.index;
    const currentStep = lessonPlanSteps[$event.index]
    currentStep.order = $event.isUp ? $event.index - 1 : $event.index + 1
    this.updatedLessonPlanSteps[replacedStep._id] = replacedStep;
    this.updatedLessonPlanSteps[currentStep._id] = currentStep;
    lessonPlanSteps
      .sort((a, b) => a.order - b.order)
    this.lessonPlanSteps = lessonPlanSteps;
    this.lessonPlanStepsSubject.next(lessonPlanSteps);

  }

  moveDouble($event: any) {
    let lessonPlanSteps: any = this.lessonPlanSteps?.length > 0 ? JSON.parse(JSON.stringify(this.lessonPlanSteps)) : JSON.parse(JSON.stringify(this.async.transform(this.lessonPlanSteps$)));

    if (($event.isUp && $event.index === 0) || (!$event.isUp && $event.index === lessonPlanSteps.length - 1)) {
      return;
    }

    if ($event.isUp) {
      lessonPlanSteps.unshift(lessonPlanSteps.splice($event.index, 1)[0]);
    } else {
      lessonPlanSteps.push(lessonPlanSteps.splice($event.index, 1)[0]);
    }

    lessonPlanSteps
      .forEach((step, index) => {
        if (step.order !== index) {
          step.order = index;
          this.updatedLessonPlanSteps[step._id] = step;
        }
      })
    this.lessonPlanSteps = lessonPlanSteps;
    this.lessonPlanStepsSubject.next(lessonPlanSteps);
  }

  onKeyUpDes(event: any) {
    const notes = this.lessonNameForm.get('notes');
    let lessonPlan: any = this.async.transform(this.lessonPlan$);
    this.lessonPlanSubject.next({...lessonPlan, notes: notes.value});
  }


  delete(index: number) {
    const dialogref = this.dialog.open(DeleteLessonPlanStepComponent, {
      data: {index: index},
      // width: '350px'
      height: '110px',
      width: '225px',
      panelClass: 'custom-confirm-dialog',
    });

    dialogref.componentInstance.functions = {
      delete: () => {
        let lessonPlanSteps: any = this.async.transform(this.lessonPlanSteps$);
        if (lessonPlanSteps[index]._id && !lessonPlanSteps[index].isNewStep) {
          // this.lessonPlanStepsService
          //   .delete(this.lessonPlanSteps[index]._id)
          //   .pipe(take(1))
          //   .subscribe();
          delete this.updatedLessonPlanSteps[lessonPlanSteps[index]._id];

          this.store.dispatch(new DeleteLessonStep({
            lessonStepId: lessonPlanSteps[index]._id,
            lessonId: lessonPlanSteps[index].lessonPlanId
          }));
          lessonPlanSteps.splice(index, 1);
          this.lessonPlanStepsSubject.next(lessonPlanSteps);
          dialogref.close();
          return;
        }

        if (lessonPlanSteps[index]._id) {
          delete this.updatedLessonPlanSteps[lessonPlanSteps[index]._id];
        }
        lessonPlanSteps.splice(index, 1);
        this.lessonPlanStepsSubject.next(lessonPlanSteps);

        dialogref.close();
      },
    };
  }

  openNoCourseWorkbookDialog() {
    this.dialog.open(NoCourseWorkbooksComponent, {width: '350px'});
  }
  updateLessonPlanSteps() {
    let lessonPlan: any = this.async.transform(this.lessonPlan$);
    let lessonPlanSteps: any = this.async.transform(this.lessonPlanSteps$);
    lessonPlanSteps.forEach((lessonPlanStep, i) => {
      lessonPlanStep = JSON.parse(JSON.stringify(lessonPlanStep));
      if (lessonPlanStep._id) {
        if (lessonPlanStep.numWords > lessonPlanStep.words.length) {
          lessonPlanStep.numWords = lessonPlanStep.words.length;
        } else if (lessonPlanStep.numWords < lessonPlanStep.words.length) {
          lessonPlanStep.words = lessonPlanStep.words.slice(0, lessonPlanStep.numWords);
        }
        // this.store.dispatch(new UpdateLessonStep({
        //   id: lessonPlanStep._id,
        //   lessonPlanStep: lessonPlanStep
        // }));
      } else {
        lessonPlanStep.lessonPlanId = lessonPlan._id;
        lessonPlanStep.order = i + 1;
        this.store.dispatch(new CreateLessonStep(lessonPlanStep));
      }
      // this.store.dispatch(new EditGetAllLessonSteps(lessonPlan._id));
      // this.store.dispatch(new ClearLessonStep());
    });
    // this.lessonPlanStepsSubject.next(lessonPlanSteps)
    this.setLessonPlanSteps = lessonPlanSteps;
  }

  beginOrSave(begin: boolean, redirect: boolean = true) {
    if (begin) {
      this.store.dispatch(new ClearActivityInitData());
      setTimeout(() => {
        this.begin();
      }, 1000);
    } else if (redirect) {
      setTimeout(() => {
        this.router.navigate([`my-curriculum`, {  selectedTabIndex: '1'}]);
      }, 500);
    }
  }

  save(begin: boolean, redirect: boolean = true) {
    let lessonPlan: any = this.async.transform(this.lessonPlan$);
    let lessonPlanSteps: any = JSON.parse(JSON.stringify(this.async.transform(this.lessonPlanSteps$)));

    this.updatedLessonPlanSteps = JSON.parse(JSON.stringify(this.updatedLessonPlanSteps));
    Object.keys(this.updatedLessonPlanSteps).forEach((id: string) => {
      let lessonPlanStep = this.updatedLessonPlanSteps[id];
      if (this.utilityService.checkActivityTab(lessonPlanStep.activityId) === 'onlyWords') {
        this.updatedLessonPlanSteps[id].tiles = [];
      }

    });
    if (!lessonPlan.name) {
      return this.errors.name = 'Name is a required field.';
    }

    if (lessonPlan._id) {
      const createNewSteps = [];
      const updatedLessonSteps = [];

      Object.keys(this.updatedLessonPlanSteps).forEach((id: string) => {
        let lessonPlanStep = this.updatedLessonPlanSteps[id];
        if (lessonPlanStep.isNewStep) {
          delete lessonPlanStep._id;
          delete lessonPlanStep.isNewStep;
          lessonPlanStep.lessonPlanId = lessonPlan._id;
          createNewSteps.push(this.lessonPlanStepsService.create(lessonPlanStep));
        } else {
          updatedLessonSteps.push(this.updatedLessonPlanSteps[id]);
        }
      });
        combineLatest(updatedLessonSteps.length === 0 ? [new Observable(observer => observer.next())] : [this.lessonPlanStepsService.updateMany(updatedLessonSteps)])
        .pipe(take(1))
        .subscribe((updatedSteps: any) => {
          combineLatest(createNewSteps.length === 0 ? [new Observable(observer => observer.next())] : createNewSteps)
          .pipe(take(1))
          .subscribe(createdSteps => {
            const createdLessonPlanSteps = createdSteps.filter(step => !!step);
            createdLessonPlanSteps.forEach(lessonPlanStep => {
              if (lessonPlanStep) {
                this.store.dispatch(new CreateLessonStepSuccess(lessonPlanStep));
                lessonPlanSteps.push(lessonPlanStep);
              }
            });
            updatedLessonSteps.forEach(lessonPlanStep => {
              this.store.dispatch(new UpdateLessonStepSuccess({
                id: lessonPlanStep._id,
                lessonPlanStep: lessonPlanStep}
              ));
              const index = lessonPlanSteps.findIndex(step => step._id === lessonPlanStep._id);
              if (index > -1) {
                lessonPlanSteps[index] = lessonPlanStep;
              }
            });

            this.lessonPlanSteps = lessonPlanSteps;
            this.lessonPlanStepsSubject.next(lessonPlanSteps);
            this.store.dispatch(new UpdateLesson({
              id: lessonPlan._id,
              lessonPlan: {
                ...lessonPlan,
                lessonPlanSteps: [...(lessonPlan.lessonPlanSteps || []), ...createdLessonPlanSteps.map(({ _id }) => _id)]
              }
            }));

            this.beginOrSave(begin, redirect);
          });
        });
    } else {
      this.subscriptions.add(
        this.lessonPlanService
        .create(lessonPlan)
        .pipe(
          switchMap((lessonPlan: any) => {
            this.lessonPlan = lessonPlan;

            const createNewSteps = [];
            Object.keys(this.updatedLessonPlanSteps).forEach((id: string) => {
              let lessonPlanStep = this.updatedLessonPlanSteps[id];
                delete lessonPlanStep._id;
                delete lessonPlanStep.isNewStep;
                lessonPlanStep.lessonPlanId = lessonPlan._id;
                createNewSteps.push(this.lessonPlanStepsService.create(lessonPlanStep));
            });

            return combineLatest(createNewSteps);
          }),
          take(1),
        )
        .subscribe((lessonPlanSteps: any) => {
          let lessonPlan = {
            ...this.lessonPlan,
            lessonPlanSteps: lessonPlanSteps.map(({_id}) => _id) || []
          };

          this.store.dispatch(new UpdateLesson({
            id: lessonPlan._id,
            lessonPlan
          }));

          this.store.dispatch(new CreateLessonSuccess(lessonPlan));

          lessonPlanSteps.forEach(lessonPlanStep => {
            this.store.dispatch(new CreateLessonStepSuccess(lessonPlanStep));
          });

          if (begin) {
            this.lessonPlan = lessonPlan;
            this.lessonPlanSubject.next(lessonPlan);

            this.lessonPlanSteps = lessonPlanSteps;
            this.lessonPlanStepsSubject.next(lessonPlanSteps);

            this.begin();
          } else if (redirect) {
            this.router.navigate(['/my-curriculum', { selectedTabIndex: '1'}]);
          }
        })
      );
    }
  }

  begin() {
    let lessonPlanSteps: any[] = this.lessonPlanSteps?.length > 0 ? this.lessonPlanSteps : this.async
      .transform(this.lessonPlanSteps$);
    lessonPlanSteps = lessonPlanSteps.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }

      if (a.order < b.order) {
        return -1;
      }

      return 0;
    });
    let lessonPlan: any = this.async.transform(this.lessonPlan$);
    const url = this.utilityService.getLessonPlanStepUrl(
      lessonPlanSteps[0].activityName,
      lessonPlanSteps[0].courseWorkbookId,
      lessonPlan._id,
      lessonPlanSteps[0]._id
    );
    this.store.dispatch(new GetLessonStep(lessonPlanSteps[0]._id));

    setTimeout(() => {
      this.router.navigate([url]);
    }, 500);
  }

  removeError(fieldName: string) {
    delete this.errors[fieldName];
  }

  showLessonPlanTextField() {
    this.showLessonPlantext = !this.showLessonPlantext;
    if (this.showLessonPlantext) {
      setTimeout(() => {
        this.lessonNameInput.nativeElement.focus();
      }, 100);
    }
  }
  showLessonPlanNoteField() {
    this.showLessonPlanNote = true;
    if (this.showLessonPlanNote) {
      setTimeout(() => {
        this.lessonDescriptionInput.nativeElement.focus();
      }, 100);
    }
  }
  lessonPlanTextOutsideClick() {
    this.showLessonPlantext = false;
  }
  lessonPlanNotesOutsideClick() {
    this.showLessonPlanNote = false;
  }

  private dispatchPostWords() {
    let lessonPlanSteps: any = this.async.transform(this.lessonPlanSteps$);
    let workbookIds = (lessonPlanSteps.map(item => item.workbookId));
      workbookIds = [...new Set(workbookIds)];
    workbookIds.forEach(workbookId => {
        const found = this.workbooks?.find(workbook => workbook._id === workbookId);
        if (found) {
          this.subscriptions.add(
            this.store.select(selectGetPostResponseByWorkbookId, {id: workbookId})
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
              if (!result) {
                // this.store.dispatch(new PostWords({
                //   id: workbookId,
                //   filter: found.filters
                // }));
              }
            })

          );
        }
      });
  }

  updatedLessonPlanStepsChanged($event: any) {
    let updatedLessonPlanStep = $event;
    // this.updatedLessonPlanSteps[$event._id] = updatedLessonPlanStep;
    const lessonPlanSteps: any = this.async.transform(this.lessonPlanSteps$) || [];
    const index = lessonPlanSteps.findIndex(item => item._id === updatedLessonPlanStep._id);

    const sort1 = updatedLessonPlanStep.words.map(item => item.wordid).sort((a, b) => a - b);
    const sort2 = lessonPlanSteps[index].words.map(item => item.wordid).sort((a, b) => a - b);

    if (updatedLessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased && JSON.stringify(sort1) !== JSON.stringify(sort2)) {
      this.workbookActivityLessonStepService.applyActiveWordsTilesOption(updatedLessonPlanStep)
        .pipe(take(1))
        .subscribe(tiles => {
          const courseWorkbookEditsEls = this.courseWorkbookEdits.toArray();
          courseWorkbookEditsEls[index].tiles = tiles;
          updatedLessonPlanStep.tiles = tiles;
          this.updatedLessonPlanSteps[$event._id] = updatedLessonPlanStep;
          this.lessonPlanWorkbooks = [
            ...new Set(lessonPlanSteps.map(lessonPlanStep => {
              const courseWorkbook = this.courseWorkbooks.find(cw => cw._id === lessonPlanStep.courseWorkbookId);
              return courseWorkbook ? courseWorkbook.workbook.name : '';
            }))
          ];
        });
      return;
    } else {
      this.updatedLessonPlanSteps[$event._id] = updatedLessonPlanStep;
      let lessonPlanSteps = [];
      for (const [key, value] of Object.entries(this.updatedLessonPlanSteps)) {
        lessonPlanSteps.push(value);
      }
      // this.lessonPlanStepsSubject.next(lessonPlanSteps)
        this.store.dispatch(new EditGetAllLessonStepsSuccess(
          {
            id: this.lessonPlan.id,
            items: lessonPlanSteps
          }
        ));

      // this.lessonPlanStepsSubject.next(lessonPlanSteps)
      this.lessonPlanWorkbooks = [
        ...new Set(lessonPlanSteps.map(lessonPlanStep => {
          const courseWorkbook = this.courseWorkbooks.find(cw => cw._id === lessonPlanStep.courseWorkbookId);
          return courseWorkbook ? courseWorkbook.workbook.name : '';
        }))
      ];
    }
  }

  fixLessonPlanStepsData() {
    // this.setLessonPlanSteps = JSON.parse(JSON.stringify(lessonPlanSteps));
    // let lessonPlanSteps = JSON.parse(JSON.stringify(lessonPlanStepsRes))

    let lessonPlanSteps;

    this.store.select(selectGetAllLessonEditedSteps)
      .pipe(
        concatMap(lessonPlanStepsRes => {
          lessonPlanSteps = JSON.parse(JSON.stringify(lessonPlanStepsRes));

          let obs$ = [];
          lessonPlanSteps.map(lessonPlanStep => {
            const courseWorkbook = this.courseWorkbooks.find(courseWorkbook => courseWorkbook._id === lessonPlanStep.courseWorkbookId);
            obs$.push(this.workbookActivityLessonStepService.fixActivityOrStepWords(lessonPlanStep, courseWorkbook));
          });

          // forkJoin(obs$)
          // .pipe(
          //   switchMap(wordsRes => {
          //     let obs2$ = [];
          //     wordsRes.forEach((words: any[], i) => {
          //       lessonPlanSteps[i].words = words
          //       lessonPlanSteps[i].numWords = words.length;
          //       // TODO: apply to other activity with only words tab on it.
          //       if (lessonPlanSteps[i].activityId === 'blackboard') {
          //         obs2$.push(of(lessonPlanSteps[i].tiles))
          //       } else {
          //         const courseWorkbook = this.courseWorkbooks.find(courseWorkbook => courseWorkbook._id === lessonPlanSteps[i].courseWorkbookId)
          //         obs2$.push(this.workbookActivityLessonStepService.fixActivityOrStepTiles(lessonPlanSteps[i], courseWorkbook))
          //       }
          //     })
          //     return forkJoin(obs2$);
          //   }),
          //   switchMap((tilesRes: any[]) => {
          //     tilesRes.forEach((tiles, i) => {
          //       lessonPlanSteps[i].tiles = tiles;
          //     })
          //     return of(lessonPlanSteps)
          //   }),
            // take(1)
          // )
          return combineLatest(obs$);
        }),
        concatMap(wordsRes => {
          let obs2$ = [];
          wordsRes.forEach((words: any[], i) => {
            lessonPlanSteps[i].words = words;
            lessonPlanSteps[i].numWords = words.length;
            // TODO: apply to other activity with only words tab on it.
            if (lessonPlanSteps[i].activityId === 'blackboard') {
              obs2$.push(of([]));
            } else {
              const courseWorkbook = this.courseWorkbooks.find(courseWorkbook => courseWorkbook._id === lessonPlanSteps[i].courseWorkbookId);
              obs2$.push(this.workbookActivityLessonStepService.fixActivityOrStepTiles(lessonPlanSteps[i], courseWorkbook));
            }
          });
          return combineLatest(obs2$);
        }),
        concatMap((tilesRes: any[]) => {
          tilesRes.forEach((tiles, i) => {
            lessonPlanSteps[i].tiles = tiles;
          });
          return of(lessonPlanSteps);
        }),
        takeWhile(lessonPlanSteps => {
          return !lessonPlanSteps;
        }, true)
      )
      .subscribe(lessonPlanSteps => {
        if (!lessonPlanSteps) {
          return;
        }
        this.lessonPlanStepsSubject.next(JSON.parse(JSON.stringify(lessonPlanSteps)));
          this.sortBy('order');
          // this.lessonPlanWorkbooks = [
          //   ...new Set(lessonPlanSteps.map(lessonPlanStep => {
          //     const courseWorkbook = this.courseWorkbooks.find(cw => cw._id === lessonPlanStep.courseWorkbookId);
          //     return courseWorkbook ? courseWorkbook.workbook.name : '';
          //   }))
          // ];
          this.dispatchPostWords();
      });

    return;


  }

  lessonPlanStepsTrackBy(index, item) {
    return item.id;
 }
}

@Component({
  selector: 'app-delete-step-menu',
  templateUrl: 'html/course-workbook-delete-menu.html',
})
export class DeleteLessonPlanStepComponent {
  functions;
  themehighlightcolor: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteLessonPlanStepComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if ((localStorage.getItem('themehighlightcolor') !== null) || (localStorage.getItem('themehighlightcolor') !== undefined)) {
        this.themehighlightcolor = localStorage.getItem('themehighlightcolor');
      } else {
        this.themehighlightcolor = 'theme1';
      }
    }

  cancelClick(): void {
    this.dialogRef.close();
  }

  delete() {
    this.functions.delete(this.data.index);
  }
}


@Component({
  selector: 'app-no-course-workbooks-dialog',
  template: `<div mat-dialog-content>
      <div>
        <h2 mat-dialog-title>No Course Workbooks</h2>
      </div>
      <mat-dialog-content class="mat-typography" >
        <span>Your Course Has No Workbooks, Please Return to the home page to add some!</span>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close>OK</button>
      </mat-dialog-actions>
    </div>`,
})
export class NoCourseWorkbooksComponent {

  constructor(
    public dialogRef: MatDialogRef<NoCourseWorkbooksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
