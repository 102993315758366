import { Injectable } from '@angular/core';
import { Actions , ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FilesService } from '../../services/files.service';
import * as actions from './file.actions';
import { AsyncPipe, DatePipe } from '@angular/common';

@Injectable()

export class FilesEffect {
    public time: number = Date.now();

    constructor(
        private actions$: Actions,
        private store$: Store,
        private fileService: FilesService,
        private date: DatePipe

    ) {}
    getFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.GetFile),
            map((action: actions.GetFile) => action.payload),
            switchMap((payload: any) => {
                this.store$.dispatch(new actions.ClearFile());
                return this.fileService.getFile(payload).pipe(
                    map((res: any) => new actions.GetFileSuccess(res)),
                    catchError((error) => of(new actions.GetFileFail(error.message)))
                );
            })
        );
    });

    getManyFiles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.GetManyFiles),
            map((action: actions.GetManyFiles) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.getManyFiles(payload).pipe(
                    map((res: any) => new actions.GetManyFilesSuccess(res)),
                    catchError((error) => of(new actions.GetManyFilesFail(error.message)))
                );
            })
        );
    });

    getManyWorkbookFiles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.GetManyWorkbookFiles),
            map((action: actions.GetManyWorkbookFiles) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.getManyWorkbookFiles(payload).pipe(
                    map((res: any) => new actions.GetManyWorkbookFilesSuccess({
                        id: payload,
                        data: res.sort(function(a, b) {
                          return a.sortOrder - b.sortOrder;
                        })
                    })),
                    catchError((error) => of(new actions.GetManyWorkbookFilesFail(error.message)))
                );
            })
        );
    });

    createFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.CreateFile),
            map((action: actions.CreateFile) => action.payload),
            switchMap((payload: any) => {

                return this.fileService.createFile(payload.fileData).pipe(
                    switchMap((res: any) => {

                        // res.name = res.name + `(${this.date.transform(this.time, 'medium')})`;
                        if (payload.isWorkbookFile) {
                            const workbookFile = {
                                fileId: res._id,
                                workbookId: payload.workbookId
                            };
                            return [
                                new actions.CreateFileSuccess(res),
                                new actions.CreateWorkbookFile(workbookFile)
                            ];
                        } else {
                            return [
                                new actions.CreateFileSuccess(res)
                            ];
                        }

                    }),
                    catchError((error) => of(new actions.CreateFileFail(error.message)))
                );
            })
        );
    });
    createManyFiles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.CreateManyFiles),
            map((action: actions.CreateFile) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.createManyFiles(payload.files).pipe(
                    switchMap((res: any) => {
                        const workbookFile = [];
                        if (payload.isWorkbookFile) {
                            res.forEach(element => {
                                workbookFile.push({
                                    fileId: element._id,
                                    workbookId: payload.workbookId
                                });
                            });
                            return [
                                new actions.CreateManyFilesSuccess(res),
                                new actions.CreateManyWorkbookFiles(workbookFile)
                            ];
                        } else {
                            return [
                                new actions.CreateManyFilesSuccess(res)
                            ];
                        }
                    }),
                    catchError((error) => of(new actions.CreateFileFail(error.message)))
                );
            })
        );
    });


    createWorkbookFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.CreateWorkbookFile),
            map((action: actions.CreateWorkbookFile) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.createWorkbookFile(payload).pipe(
                    map((res: any) => new actions.GetManyWorkbookFiles(payload.workbookId)),
                    catchError((error) => of(new actions.CreateWorkbookFileFail(error.message)))
                );
            })
        );
    });
    createManyWorkbookFiles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.CreateManyWorkbookFiles),
            map((action: actions.CreateManyWorkbookFiles) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.createManyWorkbookFiles(payload).pipe(
                    map((res: any) => new actions.GetManyWorkbookFiles(payload[0].workbookId)),
                    catchError((error) => of(new actions.CreateManyWorkbookFilesFail(error.message)))
                );
            })
        );
    });


    updateWorkbookFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.UpdateWorkbookFile),
            map((action: actions.UpdateWorkbookFile) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.updateWorkbookFile(payload.id, payload.File).pipe(
                    map((res: any) => new actions.UpdateWorkbookFileSuccess(res)),
                    catchError((error) => of(new actions.UpdateWorkbookFileFail(error.message)))
                );
            })
        );
    });

    renameFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.RenameFile),
            map((action: actions.RenameFile) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.renameFile(payload.id, payload.name).pipe(
                    map((res: any) => new actions.RenameFileSuccess(res)),
                    catchError((error) => of(new actions.RenameFileFail(error.message)))
                );
            })
        );
    });

    deleteFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.DeleteFile),
            map((action: actions.DeleteFile) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.deleteFile(payload).pipe(
                    map((res: any) => new actions.DeleteFileSuccess(payload)),
                    catchError((error) => of(new actions.DeleteFileFail(error.message)))
                );
            })
        );
    });

    deleteWorkbookFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.DeleteWorkbookFile),
            map((action: actions.DeleteWorkbookFile) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.deleteWorkbookFile(payload.collectionId, payload.itemId).pipe(
                    switchMap((res: any) => [new actions.DeleteWorkbookFileSuccess(payload), new actions.GetManyWorkbookFiles(payload.collectionId)]),
                    catchError((error) => of(new actions.DeleteWorkbookFileFail(error.message)))
                );
            })
        );
    });

    deleteManyWorkbookFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FileActionTypes.DeleteManyWorkbookFile),
            map((action: actions.DeleteManyWorkbookFile) => action.payload),
            switchMap((payload: any) => {
                return this.fileService.deleteManyWorkbookFiles(payload).pipe(
                    map((res: any) => new actions.DeleteManyWorkbookFileSuccess(res)),
                    catchError((error) => of(new actions.DeleteManyWorkbookFileFail(error.message)))
                );
            })
        );
    });

}
