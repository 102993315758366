import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

@Injectable()
export class NavbarListenerService {
   private _navBarListner = new BehaviorSubject<any>(false);
   isSidenavExpand = true;

    navBarListner() {
       return this._navBarListner.asObservable();
    }

    navbarCallback(callback: boolean) {
      this.isSidenavExpand = callback ? false : true;
      this._navBarListner.next(callback);
   }

}
