import { AddCreditCardSuccess, CancelScheduledSubscription, CancelSubscription, DeleteCreditCard, DeleteCreditCardSuccess } from './../../core/store/account/account.actions';
import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import {AccountService} from '../../core/services/account.service';
import {UserService} from '../../core/services/user.service';
import {take, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {DOCUMENT} from '@angular/common';
import {WorkbookMenuComponent} from '../../core/components/workbooks/workbooks.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UtilityService} from '../../core/services/utility.service';
import {AreYouSureComponent} from '../../core/components/are-you-sure.component';
import {PlanService} from '../../core/services/plan.service';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import { TutorialDialogComponent } from '../../core/components/support/support.component';
import { _variable_images } from '../../environments/environment';
import { AddCardComponent } from './add-card.component';
import { NavbarListenerService } from '../../core/services/navbar-listener-service..service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../core/services/data.service';
import { getAllNonAndSubscriptionCourses, isSetCourseLoaded, selectAllSubscriptions, selectGetAllSubscription, selectGetPlan, selectGetAllPlans, selectPaymentAccount } from '../../core/store';
import { AddCreditCard, GetPaymentAccount } from '../../core/store/account/account.actions';
import { SetCurriculumCourse } from '../../core/store/set-course/set-course.actions';
import {ActivityHeaderService} from "../../core/services/activity-header.service";
import { AsyncPipe } from '@angular/common';
@Component({
  selector: 'app-account',
  templateUrl: './html/account.html',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss',
    './account.scss'
  ]
})
export class AccountComponent implements OnInit, OnDestroy {
  public account: any;
  updatedInvoiceAmount: any;

  public accountSubject: any = null;
  public isLoading: any = false;

  public isLoadingSubject: any = new BehaviorSubject<any>(this.isLoading);
  public updatedInvoiceAmountSubject: any = new BehaviorSubject<any>(0);

  public isLoading$ = this.isLoadingSubject.asObservable();
  public updatedInvoiceAmount$ = this.updatedInvoiceAmountSubject.asObservable();
  public account$: any;
  public scheduledSubscriptions: any;
  public moment = moment;
  public plans: any[];
  customerId: string;
  heading: string;
  videoLink: string;
  helpImage: any;
  trialAccount = false;
  closeDialog = false;
  private unsubscribe$: Subject<void> = new Subject();
  isMobile = false;
  constructor(
    private accountService: AccountService,
    private utilityService: UtilityService,
    private userService: UserService,
    private renderer2: Renderer2,
    private planService: PlanService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private navbarListenerService: NavbarListenerService,
    private router: Router,
    private dataService: DataService,
    private store: Store,
    private async: AsyncPipe,
  ) {
    this.isMobile = this.utilityService.mobileCheck();
  }

  ngOnInit() {
    ActivityHeaderService.updateOverDeadzone(true);
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.helpImage = path + 'help-header.png';
    });
    // this.helpImage = _variable_images + 'help-header.png';
    this.heading = 'Accounts';
    this.videoLink = 'https://videos.sproutvideo.com/embed/799cd9b41510eecdf0/f0a4d4653e58584a?type=sd';

    this.customerId = (this.userService.auth0UserInfo && this.userService.auth0UserInfo.user_metadata.cusID)
      || JSON.parse(localStorage.profile).user_metadata.cusID;

      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      };

    this.store.select(selectPaymentAccount)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(account => {
        if (this.accountSubject == null) {
          this.accountSubject = new BehaviorSubject<any>(account);
          this.account$ = this.accountSubject.asObservable();
        }
        setTimeout(() => {
          this.isLoadingSubject.next(false);

        }, 300);

        this.accountSubject.next(JSON.parse(JSON.stringify(account)));
        this.updatedInvoiceAmountSubject.next(account.nextInvoice ? account.nextInvoice.amount_due : null) ;
        // this.account = JSON.parse(JSON.stringify(account));
        if (account.cards.data[0] && account.cards.data[0].owner && account.cards.data[0].owner.email === 'amount_0@stripe.com') {
          this.trialAccount = true;
        }
      });
    this.store.select(selectGetAllPlans)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (!plans) {
          return;
        }
        this.plans = JSON.parse(JSON.stringify(plans)).filter((plan) => plan.numWorkbooks !== undefined);
        // this.plans = this.plans.filter((plan) => plan.sharedContent == undefined)

        this.plans = this.plans.sort((a, b) => {
          if (a.name > b.name) {
            return -1;
          }

          if (a.name < b.name) {
            return 11;
          }

          return 0;
        });
      });
    this.store.select(selectAllSubscriptions)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        const scheduledSubs = result.data;
        this.scheduledSubscriptions = scheduledSubs.filter(sub => sub.status === "not_started");
      });

    // combineLatest([
    //   this.accountService.getPaymentAccount(this.customerId),
    //   this.planService.getAllPlans(),
    //   this.accountService.getAllSubscriptions(this.customerId)])
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe((results: any[]) => {
    //     this.account = results[0];
    //     const scheduledSubs = results[2].data;
    //     this.scheduledSubscriptions = scheduledSubs.filter(sub => sub.status === "not_started");
    //     if (results[0].cards.data[0] && results[0].cards.data[0].owner && results[0].cards.data[0].owner.email === 'amount_0@stripe.com') {
    //       this.trialAccount = true;
    //     }
    //     this.plans = results[1].sort((a, b) => {
    //       if (a.name > b.name) {
    //         return -1;
    //       }

    //       if (a.name < b.name) {
    //         return 11;
    //       }

    //       return 0;
    //     });
    //   });

    // this.createScript();

    combineLatest([
      this.store.select(isSetCourseLoaded),
      this.store.select(getAllNonAndSubscriptionCourses)
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([isDefaultCourseLoaded, allCourses]) => {
      if (!isDefaultCourseLoaded && allCourses?.length) {
        let course = allCourses[0];
        if (this.utilityService.mobileCheck()) {
          course = allCourses.find((course: any) => !course.subscriptionId);
        }
        this.store.dispatch(new SetCurriculumCourse({
          course,
          redirect: false
        }));
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  formatAmount(amount: number) {
    return this.utilityService.formatAmount(amount);
  }

  createScript() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.text = `
    (function() {
            var pn = document.createElement('script'); pn.type = 'text/javascript';
            pn.src = ('https:' == document.location.protocol ? 'https://' : 'http://')
              + 'www.pricerninja.com/api/table/057375bd6a0f45109376476d134e1bd8?pnurl=' + window.location.href;
            var s = document.getElementsByTagName('head')[0].appendChild(pn);
        })();
    `;
    this.renderer2.appendChild(document.body, s);
  }

  openSelectPlanDialog(plan: any): void {
    let account: any = this.async.transform(this.account$);
    const cardData = account.cards ? account.cards.data[0] : null;
    const currentPlan = account.subscriptions.data.length === 0 ? null : account.subscriptions.data[0]?.plan;
    const accountInfo = account;
    if (currentPlan && currentPlan.id === plan._id) {
      return this.openSamePlanDialog();
    }
    const dialogRef = this.dialog.open(AddCardMenuComponent, {
      panelClass: 'add-credit-card-modalbox',
      data: {
        selectedPlan: JSON.parse(JSON.stringify(plan)),
        currentPlan: currentPlan,
        cardData: cardData,
        utilityService: this.utilityService,
        account: accountInfo,
        scheduledSubscriptions: this.scheduledSubscriptions
      }
    });
    dialogRef.componentInstance.functions = {
      addCreditCard: (cardInfo: any) => {
        return this.addCreditCard(cardInfo);
      },
      updateCard: (card: any) => {
        this.updateCard(card);
      },
      updatePlan: (plan: any) => {
        this.updatePlan(plan);
      },
      close() {
        dialogRef.close();
      },
      updateInvoice: () => {
        this.updateInvoice(true);
      }
    };
  }

  openSamePlanDialog() {
    const dialogRef = this.dialog.open(SamePlanMenuComponent, {
      width: '300px'
    });

    dialogRef.componentInstance.functions = {
      close() {
        dialogRef.close();
      }
    };
  }

  openAddCardDialog(updatingCard: boolean) {

    let account: any = this.async.transform(this.account$);
    const cardData = account.cards && account.cards.data[0]?.type !== 'ach_credit_transfer' ? account.cards.data[0] : null;
    const currentPlan = account.subscriptions.data.length === 0 ? null : account.subscriptions.data[0]?.plan;
    const dialogRef = this.dialog.open(AddCardMenuComponent, {
      panelClass: 'add-credit-card-modalbox',
      data: {
        selectedPlan: currentPlan,
        updatingCard: updatingCard,
        trialAccount: this.trialAccount,
        cardData : cardData,
        title: this.heading,
        link:  this.videoLink,
      }
    });
    dialogRef.componentInstance.functions = {
      addCreditCard: (cardInfo: any) => {
        this.isLoadingSubject.next(true);
        this.addCreditCard(cardInfo);

      },
      updateCard: (card: any) => {
        this.isLoadingSubject.next(true);

        this.updateCard(card);

      },
      removeCard: () => {
        this.isLoadingSubject.next(true);
        this.removeCreditCard();

      },
      updatePlan: (plan: any) => {
        this.isLoadingSubject.next(true);
        this.updatePlan(plan);


      },
      updateInvoice: () => {
        this.isLoadingSubject.next(true);

        this.updateInvoice(true);
      },
      close() {
        dialogRef.close();
      }

    };
  }

  addCreditCard(cardInfo: any) {
    // return this.accountService.addCreditCard(
    //   cardInfo,
    //   this.customerId
    // ).subscribe((results) => {
    //   this.account.cards.data[0] = results;
    //   // This is a bug with this version of Material. The dialog can not close, so we're hiding it.
    //   this.changeDetector.detectChanges();

    // });
    this.store.dispatch(new AddCreditCard({
      card: cardInfo,
      id: this.customerId
    }));
    const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;
    this.store.dispatch(new GetPaymentAccount(customerId));

  }

  removeCreditCard() {
    let account: any = this.async.transform(this.account$);
    // return this.accountService.deleteCreditCard(
    //   this.userService.auth0UserInfo.user_metadata.cusID,
    //   this.account.cards.data[0].id
    // ).subscribe((results) => {
    //     delete this.account.cards.data[0];
    //     this.changeDetector.detectChanges();
    // });
    this.store.dispatch(new DeleteCreditCard(
      {
        cusId: this.userService.auth0UserInfo.user_metadata.cusID,
        id: account.cards.data[0].id
      }
    ));
  }

  updatePlan(plan: any) {
    let account: any = this.async.transform(this.account$);
    // debugger;
    if (account.subscriptions.data.length === 0) {
      account.subscriptions.data.push({plan: plan, status: 'active'});
    } else {
      account.subscriptions.data[0].plan = plan;
    }
    this.accountSubject.next(account);
    // this.accountService.getPaymentAccount(JSON.parse(localStorage.profile).user_metadata.cusID)
    // .subscribe((updatedAccount) => {
    //   this.account = updatedAccount;
    //   this.accountService.getAllSubscriptions(this.customerId).subscribe((subs: any) => {
    //     const filteredSubs = subs.data.filter(sub => sub.status === "not_started");
    //     this.scheduledSubscriptions = filteredSubs;
    //   });
    //   this.changeDetector.detectChanges();
    // });
    const filteredSubs = this.scheduledSubscriptions.filter(sub => sub.status === "not_started");
    this.scheduledSubscriptions = filteredSubs;

  }

  updateCard(card: any) {
    let account: any = this.async.transform(this.account$);

    account.cards.data[0] = card;
    this.account$.next(account);
    this.changeDetector.detectChanges();
  }
  updateInvoice(event: any) {
    let account: any = this.async.transform(this.account$);

    // this.accountService.getPaymentAccount(this.customerId).subscribe((updatedSub: any) => {
    //   if (updatedSub && updatedSub.nextInvoice) {
    //     this.updatedInvoiceAmount =  updatedSub.nextInvoice.amount_due;
    //   }
    //   this.changeDetector.detectChanges();
    // });
    this.updatedInvoiceAmountSubject.next(account.nextInvoice ? account.nextInvoice.amount_due : null) ;
  }

  openCancelSubscriptionDialog(id: string) {
    let account: any = this.async.transform(this.account$);

    const dialogRef = this.dialog.open(AreYouSureComponent, {
      panelClass: 'cancel-subscriptiton-modalbox',
      data: {
        text: 'Are you sure you want to cancel your plan? All cancellations take effect at the end of the current subscription period.\n',
        yesText: 'YES',
        noText: 'NO',
      }
    });

    dialogRef.componentInstance.functions = {
      yes: () => {
        // this.accountService
        //   .cancelSubscription(this.account.subscriptions.data[0].id)
        //   .pipe(
        //     take(1)
        //   )
        //   .subscribe(
        //     () => {
        //       if (this.scheduledSubscriptions.length > 0) {
        //         const scheduledSubs = this.scheduledSubscriptions;
        //         scheduledSubs.forEach((sub) => {
        //           this.accountService.cancelScheduledSubscription(sub.id).subscribe();
        //         });
        //       }
        //       delete this.account.nextInvoice;
        //       this.changeDetector.detectChanges();
        //       dialogRef.close();
        //     },
        //     error => console.error(error)
        //   );
        this.store.dispatch(new CancelSubscription(account.subscriptions.data[0].id));
        if (this.scheduledSubscriptions.length > 0) {
          const scheduledSubs = this.scheduledSubscriptions;
          scheduledSubs.forEach((sub) => {
            this.store.dispatch(new CancelScheduledSubscription(sub.id));
          });
        }
        dialogRef.close();

      },
      no: () => dialogRef.close()
    };
  }

}


@Component({
  selector: 'app-subscription-menu',
  templateUrl: './html/subscription-menu.html'
})
export class SubscriptionMenuComponent {
  currentPage = 1;
  functions;
  processing = false;
  skipped = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data) {
      this.currentPage = 2;
    }
  }

  addCreditCard(tokenId: string) {
    this.functions
      .addCreditCard(tokenId)
      .pipe(
        take(1)
      )
      .subscribe(
        () => {
          this.processing = false;
          this.currentPage = 1;
          this.functions.close();
        },
        (error) => this.processing = false
      );
  }

  skip() {
    this.currentPage = 2;
    this.skipped = true;
  }

  close() {
    this.functions.close();
  }
}

@Component({
  selector: 'app-same-plan-menu',
  templateUrl: './html/same-plan.html',
  styles: [
    `
      .same_plan_title {
        padding: 20px;
      }
      mat-dialog-content {
        display: block !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        font-size: 14px !important;
        letter-spacing: normal !important;
        line-height: 20px !important;
      }
    `
  ]
})
export class SamePlanMenuComponent {
  functions;

  close() {
    this.functions.close();
  }
}

@Component({
  selector: 'app-card-menu',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './html/add-card-menu.html',
  styles: [
    `
      :host ::ng-deep {
        .mat-mdc-checkbox .mdc-form-field {
          width: 20px;
          height: 20px;
          position: relative;
          left: -10px;
        }
      }
    `
  ],
  styleUrls: [
    './account.scss'
  ]
})
export class AddCardMenuComponent implements OnInit {
  functions;
  processing = false;
  currentPage = 1;
  @Input() cardData: any;
  couponAdjustedPrice: number;
  cardAddedDialog = false;
  price = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddCardMenuComponent>,
  private changeDetector: ChangeDetectorRef) {
  }
  ngOnInit() {
    this.data = {...{}, ...this.data};

    if (this.data.selectedPlan) {
      this.data.selectedPlan.price = parseFloat(this.data.selectedPlan.price);
    }

    this.price = this.data.selectedPlan?.price;
    // this.cardAddedDialog = false;
    if (this.data.account && this.data.account.discount && this.data.account.discount.coupon && this.price) {
      this.price = this.data.selectedPlan.price - (this.data.selectedPlan.price * (this.data.account.discount.coupon.percent_off / 100));
    }
  }

  updatePrice(promo: any) {
    this.data.selectedPlan["promoPrice"] = promo.amount_discount
      ? this.data.selectedPlan.price - promo.amount_off || (promo.percent_off / 100)
      : this.data.selectedPlan.price - (this.data.selectedPlan.price * (promo.percent_off / 100));
    this.price = this.data.selectedPlan["promoPrice"];
  }

  addCreditCard(tokenId: string) {
    this.functions
      .addCreditCard(tokenId);
      this.dialogRef.close();
      this.cardAddedDialog = true;
      setTimeout(() => {
        this.cardAddedDialog = false;

        this.dialogRef.close();
      }, 6000);
      this.changeDetector.detectChanges();


      // setTimeout(() => {
      //   this.changeDetector.detectChanges();

      // }, 6000);
    }

  removeCreditCard($event: any) {
    this.functions.removeCard();
  }

  updateCard(card: any) {
    this.functions.updateCard(card);
  }

  close() {
    this.functions.close();
  }
  updateInvoice() {
    this.functions.updateInvoice();
  }
}
