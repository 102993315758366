import { Store } from '@ngrx/store';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, OnChanges } from '@angular/core';
import {FilesService} from '../../../../services/files.service';
import {UserService} from '../../../../services/user.service';
import {combineLatest, fromEvent, Observable, Subject, BehaviorSubject} from 'rxjs';
import {debounceTime, map, switchMap, take, takeUntil} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {Router} from '@angular/router';
import {UtilityService} from '../../../../services/utility.service';
import { UpdateFileSuccess, ReorderWorkbookFilesSuccess } from './../../../../store/file/file.actions';
import * as reducers from '../../../../store';
import { AsyncPipe } from '@angular/common';
import {FileCardsComponent} from './file-cards.component';

@Component({
  selector: 'app-workbook-builder-files',
  templateUrl: './html/files.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './files.scss'

  ]
})

export class FilesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fileSearchBox') fileSearchBox;
  @ViewChild(FileCardsComponent) fileCardComponent: FileCardsComponent;
  @Input() workbookName: string;
  @Input() workbookId: string;
  isMobile: boolean;


  files: any[];
  public workbookFiles = [];
  public workbookFilesSubject: any = new BehaviorSubject<any>(this.workbookFiles);
  public workbookFiles$ = this.workbookFilesSubject.asObservable();

  private searchBox: any;
  private keyup$: any;
  // _iOSDevice = /iPhone|iPod|iPad|Android/.test(navigator.platform);


  addFileSubject: Subject<any> = new Subject<any>();
  addWorkbookFileSubject: Subject<any> = new Subject<any>();
  removeFileSubject: Subject<any> = new Subject<any>();
  removeWorkbookFileSubject: Subject<any> = new Subject<any>();
  searchTermSubject: Subject<any> = new Subject<any>();
  // otherItemsUpdatedSubject: Subject<any> = new Subject<any>();
  private unsubscribe$: Subject<void> = new Subject();
  public showBank = false;
  newGeneralFile = false;
  newWorkbookFile = false;
  constructor(
    private filesService: FilesService,
    private userService: UserService,
    private utilityService: UtilityService,
    private store: Store,
    private async: AsyncPipe
  ) {
    this.isMobile = this.utilityService.mobileCheck();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    combineLatest([
      // this.filesService.getManyFiles(JSON.parse(localStorage.profile).user_metadata.uid),
      this.store.select(reducers.selectGetFiles),
      this.store.select(reducers.selectGetWorkbookFiles)
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([files, workbookFiles]) => {
      if (!files || !workbookFiles) {
        // this.files = [];
        // this.workbookFiles = [];
        return;
      }
      // @ts-ignore
      if (files) {
        this.files = JSON.parse(JSON.stringify(files));
      }
      let orderedWorkbookFiles = [];
      if (workbookFiles?.length) {
      // @ts-ignore
      if (workbookFiles[0].files?.length) {
        orderedWorkbookFiles = JSON.parse(JSON.stringify(workbookFiles[0].files))?.sort((a, b) => a?.sortOrder ? a?.sortOrder - b?.sortOrder : 0) || [];
      }
      }
      this.workbookFilesSubject.next(orderedWorkbookFiles);

    });
  }

  ngAfterViewInit(): void {
    this.searchBox = this.fileSearchBox.nativeElement;
    this.keyup$ = fromEvent(this.searchBox, 'keyup');

    this.keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(200),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(searchTerm => {
        this.searchTermSubject.next(searchTerm);
      });
  }

  removeFromWorkbookEvent(workbookFileId: string) {
    // this.filesService
    //   .deleteWorkbookFile(workbookFileId)
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe(() => {
    //     const index = this.workbookFiles.findIndex(workbookFile => workbookFile._id === workbookFileId);

    //     if (index >= 0) {
    //       this.workbookFiles.splice(index, 1);
    //     }

    //     this.removeWorkbookFile(workbookFileId);
    //   });
  }
  toggleBank() {
    return this.showBank = !this.showBank;
  }
  addToWorkbookEvent(fileId: string) {
    // this.filesService
    //   .createWorkbookFile({fileId: fileId, workbookId: this.workbookId})
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe((workbookFile: any) => {
    //     this.workbookFiles.push(workbookFile);
    //     this.addWorkbookFileSubject.next(workbookFile);
    //     // this.otherItemsUpdatedSubject.next(this.workbookFiles);
    //   });
  }

  updateWorkbookFiles(workbookFiles) {
    this.workbookFilesSubject.next(workbookFiles[0].files);
    if (this.fileCardComponent) {
      this.fileCardComponent.updateFilterFilesSubject();
    }
  }

  removeEvent(fileId: string) {
    // this.filesService
    //   .deleteFile(fileId)
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe(() => {
    //     const index = this.files.findIndex(file => file._id === fileId);

    //     if (index >= 0) {
    //       this.files.splice(index, 1);
    //     }

    //     this.removeFileSubject.next(fileId);
    //   });
  }

  // theses are necessary for the situation where you are adding a file from the workbook files section or removing a workbook file from
  // the files section, otherwise a refresh would be needed to see the changes
  addFile($event: any) {
    // this.addFileSubject.next($event);
  }

  removeWorkbookFile($event) {
    // this.removeWorkbookFileSubject.next($event);
  }

  moveFile($event: any) {
    // const newFile = $event.container.data[$event.currentIndex];
    // newFile["sortOrder"] = $event.currentIndex;
    // this.filesService.updateWorkbookFile(newFile._id, newFile);
  }

  updateWorkbookFileName(event: any) {
    let workbookFiles: any = this.async.transform(this.workbookFiles$);
    let requests = [];
    workbookFiles.map((workbookFile, index) => {
      if (workbookFile.file.file === event.fileName) {
        this.store.dispatch(new UpdateFileSuccess({id: workbookFile._id, file: workbookFile}));
         workbookFile.file.name = event.name;
        return workbookFile;
      }
    });
    this.store.dispatch(new ReorderWorkbookFilesSuccess(workbookFiles));
    requests.push(this.filesService.updateWorkbookFile(this.workbookId, workbookFiles));

    combineLatest(requests)
        .pipe(take(1))
        .subscribe();
  }
}


