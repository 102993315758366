import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { StripeService } from '../../core/services/stripe.service';
import {switchMap, take} from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import {AccountService} from '../../core/services/account.service';
import {observable, Observable} from 'rxjs';
import { userInfo } from 'os';
import { CancelRenewal, CancelScheduledSubscription, ChangePlan, ScheduleNewPlan } from '../../core/store/account/account.actions';

import { GetPaymentAccount } from '../../core/store/account/account.actions';
import { ClearPlan } from '../../core/store/plan/plan.actions';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-add-card',
  templateUrl: './html/add-card.html',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss',
    './account.scss'
  ]
})
export class AddCardComponent implements OnInit {
  @Input() processing: boolean;
  @Input() selectedPlan: any;
  @Input() currentPlan: any;
  @Input() price: any = null;
  @Input() hideSkip = false;
  @Input() updatingCard: boolean;
  @Input() closeDialog: boolean;
  @Output() skipEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() processingChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() addCreditCardEvent: EventEmitter<any> = new EventEmitter(false);
  @Output() removeCredCardEvent: EventEmitter<any> = new EventEmitter(false);
  @Output() updatePrice: EventEmitter<any> = new EventEmitter<any>();
  @Output() currentPlanChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() getUpdatedInvoice: EventEmitter<any> = new EventEmitter<any>();


  cardInfo = {
    name: this.data.cardData ? this.data.cardData.name  : '',
    cvc: '',
    number: this.data.cardData ? "**** **** **** " + this.data.cardData.last4  : '',
    exp_month: this.data.cardData ? this.formatMonth(this.data.cardData.exp_month)  : '',
    exp_year: this.data.cardData ? this.data.cardData.exp_year  : '',
    expiry_date: this.data.cardData ? `${this.formatMonth(this.data.cardData.exp_month)}/${this.data.cardData.exp_year}`  : '',
  };

  errors = {
    number: false,
    cvc: false,
    expiry: false
  };

  certified = false;
  promoCode: string;
  isPromoCodeInvalid: boolean;
  showDiscount: boolean;
  promo: any;
  newCardInput = this.data.cardData ? false : true;
  demoPlan = false;
  trialCard = false;
  hasDiscount: boolean;
  public selected = true;
  constructor(private stripeService: StripeService,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<AddCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private store: Store,
    private currency: CurrencyPipe) {
  }

  ngOnInit() {
    if (this.data.account && this.data.account.discount) {
      this.hasDiscount = true;
    } else {
      this.hasDiscount = false;
    }
    if (this.selectedPlan && this.selectedPlan._id === 'demo_1s_3wkbks') {
      this.demoPlan = true;
    }

    // Trial accounts are given a fake credit card by Stripe, which messes with logic.
    if (this.data.cardData && this.data.cardData.owner && this.data.cardData.owner.email === 'amount_0@stripe.com') {
      this.trialCard = true;
      this.cardInfo = {
        name: '',
        cvc: '',
        number: '',
        exp_month: '',
        exp_year: '',
        expiry_date: ''
      };
    }
  }
  numberOnly(event): boolean {
    if (this.cardInfo.expiry_date.length === 2) {
      this.cardInfo.expiry_date = this.cardInfo.expiry_date.concat('/');
    }
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;

  }

  addCreditCard() {
    // Stripe adds a default bank account for those on a trial, this removes it so the next bill can be sent to a card instead.
    if (this.trialCard) {
      this.removeCredCardEvent.emit();
    }
    return new Observable(observer => {
      if (this.cardInfo.expiry_date.includes('/')) {
        const splitdate = this.cardInfo.expiry_date.split("/");
        this.cardInfo.exp_month = '';
        this.cardInfo.exp_year = '';
        this.cardInfo.exp_month = splitdate[0].replace(/\s/g, "");
        this.cardInfo.exp_year = splitdate[1].replace(/\s/g, "");
      }
      this.errors.number = this.errors.cvc = this.errors.expiry = false;
      this.processing = true;
      this.processingChange.emit(this.processing);

      this.errors = { ...this.errors, ...this.stripeService.validateCreditCard(this.cardInfo) };

      // exit if there's an error
      if (Object.keys(this.errors).find(key => this.errors[key])) {
        this.processing = false;
        return this.processingChange.emit(this.processing);
      }

      this.stripeService.createToken(this.cardInfo)
        .pipe(
          take(1)
        )
        .subscribe((token: any) => {
          this.processing = false;
          this.processingChange.emit(this.processing);
          this.addCreditCardEvent.emit(token.id);
          this.trialCard = false;
          observer.next();
        });
    });
  }

  skip() {
    this.skipEvent.emit();
  }

  onCancel() {
    this.dialogRef.close();
  }

  getPromoCode() {
    this.accountService
      .getPromoCode(this.promoCode)
      .pipe(
        take(1)
      )
      .subscribe(
        plan => {
          if (this.hasDiscount && this.data.account) {
            this.accountService.deleteCustomerPromo(this.data.account.id).subscribe();
          }
          this.isPromoCodeInvalid = false;
          this.hasDiscount = false;
          this.showDiscount = true;
          this.promo = plan;
          this.updatePrice.emit(plan);
        },
        error => {
          // This is to remove the warning after applying a new code. When the component reinitializes it'll check again.
          this.isPromoCodeInvalid = true;
          this.showDiscount = false;
        }
      );
  }

  changePlan() {
    return new Observable(observer => {
      this.cancelOtherScheduledSubscriptions();
      this.processing = true;
      if (this.selectedPlan) {
        let isDowngrade = false;
        if (this.currentPlan) {
          const selectedPlanValue = isNaN(Number(this.selectedPlan.price) ) ? 0 : Number(this.selectedPlan.price);
          const currentPlanValue: number = isNaN((this.currentPlan.amount / 100)) ? 0 : (this.currentPlan.amount / 100);
          isDowngrade = selectedPlanValue < currentPlanValue;
          }
          const planRequest: any = {
            planId: this.selectedPlan._id,
            promoId: this.promoCode,
            isDowngrade: isDowngrade,
            billing_cycle_anchor: 'now',
          };
      // Downgrading keeps the current plan an schedules the plan at the end of the billing cycle.
      if (isDowngrade) {
        this.accountService.cancelRenewal(this.data.account.subscriptions.data[0].id).subscribe((results) => {
          // get the previous end subscription date, and schedule a new subscription starting that date.
          planRequest.billing_cycle_anchor = this.data.account.subscriptions.data[0].current_period_end;
          this.accountService.scheduleNewPlan(planRequest, this.data.account.subscriptions.data[0].current_period_end)
          .subscribe((result) => {
            const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;
            this.store.dispatch(new GetPaymentAccount(customerId));
            this.store.dispatch(new ClearPlan());

            this.getUpdatedInvoice.emit();
            this.dialogRef.close();
          });
        });
      } else {
        planRequest.proration_behavior = 'create_prorations';
        this.accountService
          .changePlan(planRequest)
          .pipe(
            take(1)
          )
          .subscribe((subscription: any) => {
            const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;
            this.store.dispatch(new GetPaymentAccount(customerId));
            this.store.dispatch(new ClearPlan());
            this.currentPlanChange.emit(subscription.plan);
            this.getUpdatedInvoice.emit(true);
            this.processing = false;
            observer.next();
          });
        }
      }
    });
  }
   changePlanSync() {
    this.cancelOtherScheduledSubscriptions();
    // this.selected = !this.selected;
    this.processing = true;
    if (this.selectedPlan) {
      let isDowngrade = false;
      if (this.currentPlan) {
        const selectedPlanValue = Number(this.selectedPlan.price.replace(/\D/g, ''));
        isDowngrade = selectedPlanValue < Number(this.currentPlan.amount);
      }
      const planRequest: any = {
        planId: this.selectedPlan._id,
        promoId: this.promoCode,
        isDowngrade: isDowngrade,
        billing_cycle_anchor: 'now',
        proration_behavior: 'create_prorations'
      };
    // Downgrading keeps the current plan an schedules the plan at the end of the billing cycle.
    if (isDowngrade) {
      // this.accountService.cancelRenewal(this.data.account.subscriptions.data[0].id).subscribe((results) => {
      //   // get the previous end subscription date, and schedule a new subscription starting that date.
      //   this.accountService.scheduleNewPlan(planRequest, this.data.account.subscriptions.data[0].current_period_end)
      //   .subscribe((result) => {
      //     this.getUpdatedInvoice.emit();
      //     // this.dialogRef.close();
      //   });
      // });
      this.store.dispatch(new CancelRenewal(this.data.account.subscriptions.data[0].id));
      this.store.dispatch(new ScheduleNewPlan({
        plan: planRequest,
        date: this.data.account.subscriptions.data[0].current_period_end
      }));
      this.getUpdatedInvoice.emit();
    } else {
      // this.accountService
      //   .changePlan(planRequest)
      //   .pipe(
      //     take(1)
      //   )
      //   .subscribe((subscription: any) => {
      //     this.currentPlanChange.emit(subscription.plan);
      //     this.getUpdatedInvoice.emit(true);
      //     this.processing = false;
      //   });
        this.store.dispatch(new ChangePlan(planRequest));
        this.getUpdatedInvoice.emit(true);
        this.processing = false;
      }
    }

   }

  save(newCard: boolean) {
    if (newCard) {
      if (this.selectedPlan && !this.updatingCard) {
        return this.addCreditCard()
        .pipe(
          take(1),
          switchMap(() => {
            return this.changePlan();
          })
        )
        .subscribe((results) => {
        });
      } else {
        return this.addCreditCard().pipe(take(1)).subscribe((results) => {
        });
      }
    } else {
      this.changePlan()
        .pipe(take(1))
        .subscribe(() => {
          this.processing = false;
          this.dialogRef.close();
        });
      }
  }

  useExistingCard() {
    this.changePlan()
    .pipe(take(1))
    .subscribe(() => {
      this.processing = false;
      this.dialogRef.close();
    });
  }

  toggleNewCardInput() {
    this.cardInfo.name = '';
    this.cardInfo.number = '';
    this.cardInfo.cvc = '';
    this.cardInfo.exp_month = '';
    this.cardInfo.exp_year = '';
    this.cardInfo.expiry_date = '';
    this.newCardInput = !this.newCardInput;
  }

  formatMonth(month: number) {
    if (month < 10) {
      const formattedMonth = '0' + String(month);
      return formattedMonth;
    } else {
      return month;
    }
  }

  cancelOtherScheduledSubscriptions() {
    if (this.data.scheduledSubscriptions) {
      const scheduledSubs = this.data.scheduledSubscriptions;
      scheduledSubs.forEach((sub) => {
        // this.accountService.cancelScheduledSubscription(sub.id).subscribe();
        this.store.dispatch(new CancelScheduledSubscription(sub.id));
      });
    }
  }
}
