import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TilesUpdatingOptionEnum, WordsUpdatingOptionEnum } from '../../core/components/course-workbook-edit/course-workbook-edit.component';
import { TutorialDialogComponent } from '../../core/components/support/support.component';
import { DataService } from '../../core/services/data.service';
import { getCurrentSetting } from '../../core/store';
import { UpdateSetting } from '../../core/store/settings/settings.actions';

@Component({
  selector: 'app-new-advanced',
  templateUrl: './new-advanced.component.html',
  styleUrls: ['./new-advanced.component.scss']
})
export class NewAdvancedComponent implements OnInit, OnDestroy {

  public userSettings: any;
  public isLoading = true;
  private unsubscribe$: Subject<void> = new Subject();
  public helpImage: string;


  constructor(private store: Store, private dialog: MatDialog, private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.helpImage = path + 'help-header.png';
    });

    this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe(setting => {
      if (!setting) {
        this.userSettings = null;
        this.isLoading = true;
        return;
      }
      this.userSettings = JSON.parse(JSON.stringify(setting));
      if (!this.userSettings?.tileUpdating) {
        this.userSettings.tileUpdating = TilesUpdatingOptionEnum.Manual;
      }
      if (!this.userSettings?.wordUpdating) {
        this.userSettings.wordUpdating = WordsUpdatingOptionEnum.Manual;
      }
      this.isLoading = false;

    });
  }

  openTutorialDialog(videoTitle, videoLink): void {
    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      data: {
        title: videoTitle,
        link:  videoLink
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  updateSetting() {
    this.store.dispatch(new UpdateSetting(this.userSettings));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
