import { CreateManyFiles, CreateWorkbookFile, DeleteFile, DeleteWorkbookFile, DeleteWorkbookFileSuccess, ReorderWorkbookFilesSuccess, RenameFileSuccess } from './../../../../store/file/file.actions';
import { Store } from '@ngrx/store';
import { AsyncPipe, DatePipe } from '@angular/common';
import {fromEvent, Observable, combineLatest, Subscription, Subject, BehaviorSubject} from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Inject,
  ViewChild,
  ElementRef, OnDestroy, ChangeDetectionStrategy, ViewChildren
} from '@angular/core';
import {ViewPDFComponent} from './view-pdf.component';
import {
  faFilePdf,
  faPlus,
  faTrash,
  faMinus,
  faRulerHorizontal
} from '@fortawesome/free-solid-svg-icons';
import { FilesService} from '../../../../services/files.service';
import { UserService } from '../../../../services/user.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {ActivatedRoute, Router} from '@angular/router';
import { CreateFile, GetManyFiles, GetManyWorkbookFiles} from '../../../../store/file/file.actions';
import * as reducers from '../../../../store';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-file-cards',
  templateUrl: './html/file-cards.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './files.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FileCardsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fileSelect', {static: false}) fileSelect: ElementRef;
  // @Input() files: any[];
  files: any[];
  // @Input() otherItems: any[];
  otherItems: any[];
  @Input() isAccountFile: boolean;
  @Input() uploadNew: boolean;
  @Input() isBank = false;
  @Input() searchTermObservable: Observable<any>;
  @Input() orderable: boolean;
  // @Input() otherFilesUpdatedObservable: Observable<any>;
  // @Output() moveFile: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() addFile: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() removeWorkbookFile: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() addToWorkbook: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() removeFromWorkbook: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() remove: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() updateWorkbookFiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateWorkbookFileName: EventEmitter<any> =  new EventEmitter<any>();
  private newFile: any;
  private subscriptions: Subscription[] = [];
  public renaming = false;
  public renameControl: string;
  public searchTerm = "";
  public loadingFile = false;
  public largeFile = true;
  public wrongType = true;
  public fileNum = 0;
  public tooMany = false;
  public time: number = Date.now();
  public filteredFiles: any[] = [];
  public workbookFiles: any;
  public filteredFilesSubject: any = new BehaviorSubject<any>(this.filterFiles);

  public filteredFiles$ = this.filteredFilesSubject.asObservable();

  workbookId: string;

  faFile = faFilePdf;
  faPlus = faPlus;
  faTrash = faTrash;
  faMinus = faMinus;

  private unsubscribe$: Subject<void> = new Subject();
  @ViewChildren('contentCard') contentCards;
  isDuplicateName = false;
  otherFiles: any;

  constructor(
    private filesService: FilesService,
    private userService: UserService,
    public dialog: MatDialog,
    private pdf: ViewPDFComponent,
    private store: Store,
    private route: ActivatedRoute,
    private async: AsyncPipe,
    private date: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.workbookId = this.route.snapshot.paramMap.get('workbookId');

    this.init();

    this.subscriptions.push(this.searchTermObservable.subscribe(searchTerm => {
      this.searchTerm = searchTerm;

      this.filterFiles();
    }));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkCardMultiline();
    }, 1000);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
 init(): void {
  combineLatest([
    this.store.select(reducers.selectGetFiles),
    this.store.select(reducers.selectGetWorkbookFiles)
  ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([files, workbookFiles]) => {
      if (!files || !workbookFiles) {
        this.files = [];
        this.otherItems = [];
        return;
      }
      if (workbookFiles[0]?.files) {
        this.workbookFiles = workbookFiles;
        this.updateWorkbookFiles.emit(workbookFiles);
      }
      if (this.isAccountFile) {
        this.files = JSON.parse(JSON.stringify(files));
        this.otherItems = JSON.parse(JSON.stringify(workbookFiles[0]?.files || []));

      } else {
        this.files = JSON.parse(JSON.stringify(workbookFiles[0]?.files || []));
        this.otherItems = JSON.parse(JSON.stringify(files));
      }
      this.otherFiles = JSON.parse(JSON.stringify(files));
      // this.filteredFiles = this.files.slice();
      this.filteredFilesSubject.next(this.files);

      if (this.isBank) {
        this.files = this.files
        .sort((a: any, b: any) => {
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
          return 0;

        });
      }
      this.itemFilter = this.itemFilter.bind(this);
      this.updateFiles();

    });


 }

 updateFilterFilesSubject() {
   if (!this.isBank) {
     this.filesService.getManyWorkbookFiles(this.workbookId).pipe(take(1)).subscribe(workbookFiles => {
       if (workbookFiles[0]?.files) {
         this.workbookFiles = workbookFiles;
         // this.updateWorkbookFiles.emit(workbookFiles);
       }
       this.files = JSON.parse(JSON.stringify(workbookFiles[0]?.files || []));
       this.filteredFilesSubject.next(this.files);
     });
   }
 }

  filterFiles() {
    if (this.searchTerm !== "") {
      let filteredFiles = this.files.filter(f => {
        if (f.file.name) {
          return f.file.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
        }
        return f.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
      });
      this.filteredFilesSubject.next(filteredFiles);
    } else {
      this.filteredFilesSubject.next(this.files.slice());
    }
  }

  updateFiles() {
    this.files = this.files?.map(file => {
      if (this.isAccountFile) {
        file.isAdded = this.otherItems.findIndex(oi => file._id === oi.fileId) >= 0;
      } else {
        file.isAdded = this.otherItems.findIndex(oi => file.fileId === oi._id) >= 0;
      }

      this.filterFiles();

      return file;
    });

  }

  addToWorkbookEvent(itemId: string) {
    // this.addToWorkbook.emit(itemId);
    let ownerKey = this.userService.getUserInfo().user_metadata.uid;

    const workbookFile = {
      fileId: itemId,
      workbookId: this.workbookId,
      ownerKey: ownerKey
    };
    this.store.dispatch(new CreateWorkbookFile(workbookFile));
  }

  uploadStatus(status: boolean) {
    if (this.uploadNew) {
      this.uploadFile();
    }
    return this.uploadNew;
  }

  openPDF(file: any): void {
    if (!file.fileId ) {
      const dialogRef = this.dialog.open(ViewPDFComponent, {
        panelClass: 'type-edit-modalbox',
        width: '800px',
        height: 'calc(100% - 75px)',
        data: {source: file._id}
      });

    } else {
      const dialogRef = this.dialog.open(ViewPDFComponent, {
        panelClass: 'type-edit-modalbox',
        width: '800px',
        height: 'calc(100% - 75px)',
        data: {source: file.fileId}
      });

    }
  }

  removeEvent(itemId: string) {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    const dialogRef = this.dialog.open(DeleteFileDialogComponent, {
      height: '110px',
      width: '200px',
      panelClass: 'custom-confirm-dialog',
    });
    dialogRef.componentInstance.functions = {
      deleteContent: () => {
        // this.remove.emit(itemId);

        if (this.isAccountFile) {
          // this.removeWorkbookFile.emit(itemId);
          let deleteFileItem = filteredFiles.find(item => {
            return item._id === itemId;
          });
          let deleteWorkbookFileItem = this.otherItems.find(item => {
            return item.fileId === itemId;
          });

          if (deleteFileItem) { this.store.dispatch(new DeleteFile(deleteFileItem._id)); }
          if (deleteWorkbookFileItem) { this.store.dispatch(new DeleteWorkbookFileSuccess(deleteWorkbookFileItem._id)); }

        } else {
          this.store.dispatch(new DeleteWorkbookFile({itemId: itemId, collectionId: this.workbookId}));
        }
      }

    };
  }

  itemFilter(file: any) {
    if (!this.searchTerm || this.searchTerm === '') {
      return  true;
    }

    return file.name.indexOf(this.searchTerm) >= 0;
  }
  selectFile(event: any) {
    this.fileNum = 0;
    const files: any = Array.from(event.target.files);
    this.largeFile = files.every((file: any) => file.size <= 5000000);
    this.wrongType = files.every((file: any) => file.type === 'application/pdf');

    if (this.largeFile ||  this.wrongType) {
      this.newFile = files;
      this.fileNum = files.length;
    }
    if (files.length > 1 ) {
      this.tooMany = true;
    } else {
      this.tooMany = false;
    }
    const exist = !!this.otherFiles?.find(file => file?.name === files[0]?.name);

    if (exist) {
      this.isDuplicateName = true;
    } else {
      this.isDuplicateName = false;
    }
  }

  uploadFile() {
    let files = [];
    if (this.newFile.length > 1 && this.isAccountFile) {
      this.tooMany = true;
      return;
    } else if (this.newFile.length > 1) {
      this.tooMany = false;
      return new Observable(observer => {
        // this.newFile = this.newFile.map((newFile: any) => JSON.parse(JSON.stringify(newFile)))
        if (this.newFile.length <= 12) {
          for (let i = 0; i < this.newFile.length; i++) {
            this.loadingFile = true;
            this.toBase64(this.newFile[i])
            .pipe(
              take(1),
              switchMap((fileBase64: any) => {
                const file: any = {};
                file.name = this.newFile[i].name;
                file.file = fileBase64.split('base64,').pop();
                if (i === (this.newFile.length - 1)) {
                  this.newFile = null;
                  this.fileSelect.nativeElement.value = null;
                }
                return new Observable(observer => observer.next(file));
                // return this.filesService.createFile(file);
            })
            )
            .subscribe((file: any) => {
            //  if (!this.isAccountFile) {
              //  this.addToWorkbook.emit(file._id);
              //  this.addFile.emit(file);
            //  } else {
              //  this.files.push(file);
            //  }
            files.push(file);
             this.fileNum = 0;



            });

          }
          setTimeout(() => {
            observer.next(files);

          }, 1000);
        }
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((files: any) => {
        this.store.dispatch(new CreateManyFiles({
          files: files,
          workbookId: this.workbookId,
          isWorkbookFile: !this.isAccountFile
        }));
        setTimeout(() => {
          const uid = JSON.parse(localStorage.profile).user_metadata.uid;
          this.store.dispatch(new GetManyFiles(uid));
          this.store.dispatch(new GetManyWorkbookFiles(this.workbookId));

          // this.init();
        }, 1000);
        setTimeout(() => {
          // const uid = JSON.parse(localStorage.profile).user_metadata.uid;
          // this.store.dispatch(new GetManyFiles(uid));
          this.loadingFile = false;
          this.init();
        }, 1000);

      });

    } else {
      this.tooMany = false;
      const file: any = {};
      this.newFile.forEach((newFile, index, array ) => {
        this.loadingFile = true;
        this.toBase64(newFile)
        .pipe(
          take(1),
          tap((fileBase64: any) => {
            file.name = this.newFile[index].name + `(${this.date.transform(this.time, 'medium')})`;
            file.file = fileBase64.split('base64,').pop();
            if (index === (this.newFile.length - 1)) {
              this.newFile = null;
              this.fileSelect.nativeElement.value = null;
            }
            // return this.filesService.createFile(file);
            this.store.dispatch(new CreateFile({
              fileData: file,
              workbookId: this.workbookId,
              isWorkbookFile: !this.isAccountFile
            }));

        })
        )
        .subscribe((file: any) => {
        //  if (!this.isAccountFile) {
          //  this.addToWorkbook.emit(file._id);
          //  this.addFile.emit(file);
        //  } else {
          //  this.files.push(file);
        //  }
         this.fileNum = 0;

         setTimeout(() => {
          const uid = JSON.parse(localStorage.profile).user_metadata.uid;
          // this.store.dispatch(new GetManyFiles(uid));
          // this.store.dispatch(new GetManyWorkbookFiles(this.workbookId));

          // this.init();
        }, 1000);
        setTimeout(() => {
          // const uid = JSON.parse(localStorage.profile).user_metadata.uid;
          // this.store.dispatch(new GetManyFiles(uid));
          this.loadingFile = false;
          this.init();
        }, 1000);


        });

      });
    }
  }

  renameFileInput(index: number) {
    this.files[index]['renaming'] = true;
  }

  renameFile(index: number) {
    const file = this.files[index];
    const dialogRef = this.dialog.open(EditFileDialogComponent, {
      width: '450px',
      panelClass: 'type-edit-modalbox',
      data: {
        file: file
      }

    });
    dialogRef.componentInstance.functions = {
      editContent: (newName: string) => {
        this.files[index].name = newName;
        this.filesService.renameFile(file._id, newName).subscribe();
        this.updateWorkbookFileName.emit({fileName: file.file, name: newName});

        this.store.dispatch(new RenameFileSuccess({
          id: file._id,
          name: newName
        }));

      }
    };

    this.files[index]['renaming'] = false;
    this.renameControl = '';
  }

  private toBase64 (file: any) {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => observer.next(reader.result);
      reader.onerror = error => observer.error(error);
    });
  }
  reorderFiles($event) {
    let moveFiles = (arr, fromIndex, toIndex) => {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    };
    if ($event.container.id === $event.previousContainer.id) {
      // moveItemInArray($event.container.data, $event.previousIndex, $event.currentIndex);
      let files = moveFiles($event.container.data, $event.previousIndex, $event.currentIndex);
      files = JSON.parse(JSON.stringify(files));
      let requests = [];

      for (let i = 0; i < this.files.length; i++) {
        files[i]["sortOrder"] = i;
      }
      requests.push(this.filesService.updateWorkbookFile(this.workbookId, files));

      this.filteredFilesSubject.next(files);

      combineLatest(requests)
        .pipe(take(1))
        .subscribe();

    } else {
      transferArrayItem(
        $event.previousContainer.data,
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex);
    }
  }
  sortBy(prop: string): any[] {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    return filteredFiles.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }

      if (a[prop] > b[prop]) {
        return 1;
      }

      return 0;
    });
  }
  checkCardMultiline() {
    this.contentCards._results.forEach(card => {
      if (card.nativeElement.offsetHeight > 64) {
        card.nativeElement.style.display = 'flex';
      } else {
        card.nativeElement.style.display = 'inline-block';
      }
    });
  }

}


@Component({
  selector: 'app-delete-file-dialog',
  templateUrl: './html/delete-file-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './files.scss'

  ],
  styles: [
    `:host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }`
  ]
})

export class DeleteFileDialogComponent  {
  functions;

  constructor(public dialogRef: MatDialogRef<DeleteFileDialogComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  save() {
    this.functions.deleteContent();
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
    // this.route.navigate([`/workbook-builder/${this.data.workbookId}`, {isFromLibrary: false, selectedTabIndex: 1}])

  }

}


@Component({
  selector: 'app-edit-file-dialog',
  templateUrl: './html/rename-file-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
  ]
})

export class EditFileDialogComponent implements OnInit, OnDestroy {
  functions;
  public keys: any;
  public value: string;
  private unsubscribe$: Subject<void> = new Subject();
  private files;
  renameFileForm: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<EditFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private formBuilder: UntypedFormBuilder
) {
  this.value = this.data.name;
  this.renameFileForm = this.formBuilder.group({
    name: [this.data.file.name, [Validators.required, (control: AbstractControl) => {
      const exist = !!this.files?.find(file => file.name === control.value && file._id !== this.data?.file._id);
      if (exist) {
        return { duplicate: true };
      }
      return false;
    }]]
  });
}

  ngOnInit() {
    this.store.select(reducers.selectGetFiles)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(files => {
      this.files = files;
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // edit(event: any) {
  //   this.value = event.target.value;
  // }

  save() {
    this.functions.editContent(this.renameFileForm.value.name);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
