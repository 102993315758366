import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum FileActionTypes {
    GetFile = '[FileService] Get File',
    GetFileSuccess = '[FileService] Get  File Success',
    GetFileFail = '[FileService] Get  File Fail',

    GetManyFiles = '[FileService] Get Many Files',
    GetManyFilesSuccess = '[FileService] Get Many Files Success',
    GetManyFilesFail = '[FileService] Get Many Files Fail',

    GetManyWorkbookFiles = '[FileService] Get Many Workbook Files',
    GetManyWorkbookFilesSuccess = '[FileService] Get Many Workbook Files Success',
    GetManyWorkbookFilesFail = '[FileService] Get Many Workbook Files Fail',

    CreateFile = '[FileService] Create File',
    CreateFileSuccess = '[FileService] Create File Success',
    CreateFileFail = '[FileService] Create File Fail',

    CreateWorkbookFile = '[FileService] Create Workbook File',
    CreateWorkbookFileSuccess = '[FileService] Create Workbook File Success',
    CreateWorkbookFileFail = '[FileService] Create Workbook File Fail',

    CreateManyFiles = '[FileService] Create Many Files',
    CreateManyFilesSuccess = '[FileService] Create Many Files Success',
    CreateManyFilesFail = '[FileService] Create Many Files Fail',

    CreateManyWorkbookFiles = '[FileService] Create Many Workbook Files',
    CreateManyWorkbookFilesSuccess = '[FileService] Create Many Workbook Files Success',
    CreateManyWorkbookFilesFail = '[FileService] Create Many Workbook Files Fail',


    UpdateWorkbookFile = '[FileService] Update File',
    UpdateWorkbookFileSuccess = '[FileService] Update File Success',
    UpdateWorkbookFileFail = '[FileService] Update File Fail',

    RenameFile = '[FileService] Rename File',
    RenameFileSuccess = '[FileService] Rename File Success',
    RenameFileFail = '[FileService] Rename File Fail',

    UpdateFile = '[FileService] Update File',
    UpdateFileSuccess = '[FileService] Update File Success',
    UpdateFileFail = '[FileService] Update File Fail',

    DeleteFile = '[FileService] Delete File',
    DeleteFileSuccess = '[FileService] Delete File Success',
    DeleteFileFail = '[FileService] Delete File Fail',

    ClearFile = '[FileService] Clear File',
    ClearFileSuccess = '[FileService] Clear File Success',
    ClearFileFail = '[FileService] Clear File Fail',

    DeleteWorkbookFile = '[FileService] Delete Workbook File',
    DeleteWorkbookFileSuccess = '[FileService] Delete Workbook File Success',
    DeleteWorkbookFileFail = '[FileService] Delete Workbook File Fail',

    DeleteManyWorkbookFile = '[FileService] Delete Many Workbook File',
    DeleteManyWorkbookFileSuccess = '[FileService] Delete Many Workbook File Success',
    DeleteManyWorkbookFileFail = '[FileService] Delete Many Workbook File Fail',

    ReorderWorkbookFilesSuccess = '[FileService] Reorder Files Success',
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
 export class GetFile implements Action {
    readonly type = FileActionTypes.GetFile;

    constructor(public payload: any) {}
}
export class GetFileSuccess implements Action {
    readonly type = FileActionTypes.GetFileSuccess;

    constructor(public payload: any) {}
}
export class GetFileFail implements Action {
    readonly type = FileActionTypes.GetFileFail;

    constructor(public payload: any) {}
}

export class GetManyFiles implements Action {
    readonly type = FileActionTypes.GetManyFiles;

    constructor(public payload: any) {}
}
export class GetManyFilesSuccess implements Action {
    readonly type = FileActionTypes.GetManyFilesSuccess;

    constructor(public payload: any) {}
}
export class GetManyFilesFail implements Action {
    readonly type = FileActionTypes.GetManyFilesFail;

    constructor(public payload: any) {}
}

export class GetManyWorkbookFiles implements Action {
    readonly type = FileActionTypes.GetManyWorkbookFiles;

    constructor(public payload: any) {}
}
export class GetManyWorkbookFilesSuccess implements Action {
    readonly type = FileActionTypes.GetManyWorkbookFilesSuccess;

    constructor(public payload: any) {}
}
export class GetManyWorkbookFilesFail implements Action {
    readonly type = FileActionTypes.GetManyWorkbookFilesFail;

    constructor(public payload: any) {}
}

export class CreateFile implements Action {
    readonly type = FileActionTypes.CreateFile;

    constructor(public payload: any) {}
}
export class CreateFileSuccess implements Action {
    readonly type = FileActionTypes.CreateFileSuccess;

    constructor(public payload: any) {}
}
export class CreateFileFail implements Action {
    readonly type = FileActionTypes.CreateFileFail;

    constructor(public payload: any) {}
}

export class CreateWorkbookFile implements Action {
    readonly type = FileActionTypes.CreateWorkbookFile;

    constructor(public payload: any) {}
}
export class CreateWorkbookFileSuccess implements Action {
    readonly type = FileActionTypes.CreateWorkbookFileSuccess;

    constructor(public payload: any) {}
}
export class CreateWorkbookFileFail implements Action {
    readonly type = FileActionTypes.CreateWorkbookFileFail;

    constructor(public payload: any) {}
}

export class CreateManyFiles implements Action {
    readonly type = FileActionTypes.CreateManyFiles;

    constructor(public payload: any) {}
}
export class CreateManyFilesSuccess implements Action {
    readonly type = FileActionTypes.CreateManyFilesSuccess;

    constructor(public payload: any) {}
}
export class CreateManyFilesFail implements Action {
    readonly type = FileActionTypes.CreateManyFilesFail;

    constructor(public payload: any) {}
}

export class CreateManyWorkbookFiles implements Action {
    readonly type = FileActionTypes.CreateManyWorkbookFiles;

    constructor(public payload: any) {}
}
export class CreateManyWorkbookFilesSuccess implements Action {
    readonly type = FileActionTypes.CreateManyWorkbookFilesSuccess;

    constructor(public payload: any) {}
}
export class CreateManyWorkbookFilesFail implements Action {
    readonly type = FileActionTypes.CreateManyWorkbookFilesFail;

    constructor(public payload: any) {}
}


export class UpdateWorkbookFile implements Action {
    readonly type = FileActionTypes.UpdateWorkbookFile;

    constructor(public payload: any) {}
}
export class UpdateWorkbookFileSuccess implements Action {
    readonly type = FileActionTypes.UpdateWorkbookFileSuccess;

    constructor(public payload: any) {}
}
export class UpdateWorkbookFileFail implements Action {
    readonly type = FileActionTypes.UpdateWorkbookFileFail;

    constructor(public payload: any) {}
}

export class RenameFile implements Action {
    readonly type = FileActionTypes.RenameFile;

    constructor(public payload: any) {}
}
export class RenameFileSuccess implements Action {
    readonly type = FileActionTypes.RenameFileSuccess;

    constructor(public payload: any) {}
}
export class RenameFileFail implements Action {
    readonly type = FileActionTypes.RenameFileFail;

    constructor(public payload: any) {}
}

export class UpdateFile implements Action {
    readonly type = FileActionTypes.UpdateFile;

    constructor(public payload: any) {}
}
export class UpdateFileSuccess implements Action {
    readonly type = FileActionTypes.UpdateFileSuccess;

    constructor(public payload: any) {}
}
export class UpdateFileFail implements Action {
    readonly type = FileActionTypes.UpdateFileFail;

    constructor(public payload: any) {}
}

export class DeleteFile implements Action {
    readonly type = FileActionTypes.DeleteFile;

    constructor(public payload: any) {}
}
export class DeleteFileSuccess implements Action {
    readonly type = FileActionTypes.DeleteFileSuccess;

    constructor(public payload: any) {}
}
export class DeleteFileFail implements Action {
    readonly type = FileActionTypes.DeleteFileFail;

    constructor(public payload: any) {}
}

export class ClearFile implements Action {
    readonly type = FileActionTypes.ClearFile;

}
export class ClearFileSuccess implements Action {
    readonly type = FileActionTypes.ClearFileSuccess;

    constructor(public payload: any) {}
}
export class ClearFileFail implements Action {
    readonly type = FileActionTypes.ClearFileFail;

    constructor(public payload: any) {}
}

export class DeleteWorkbookFile implements Action {
    readonly type = FileActionTypes.DeleteWorkbookFile;

    constructor(public payload: any) {}
}
export class DeleteWorkbookFileSuccess implements Action {
    readonly type = FileActionTypes.DeleteWorkbookFileSuccess;

    constructor(public payload: any) {}
}
export class DeleteWorkbookFileFail implements Action {
    readonly type = FileActionTypes.DeleteWorkbookFileFail;

    constructor(public payload: any) {}
}

export class DeleteManyWorkbookFile implements Action {
    readonly type = FileActionTypes.DeleteManyWorkbookFile;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookFileSuccess implements Action {
    readonly type = FileActionTypes.DeleteManyWorkbookFileSuccess;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookFileFail implements Action {
    readonly type = FileActionTypes.DeleteManyWorkbookFileFail;

    constructor(public payload: any) {}
}

export class ReorderWorkbookFilesSuccess implements Action {
  readonly type = FileActionTypes.ReorderWorkbookFilesSuccess;

  constructor(public payload: any) {}
}

export type FileActions
= GetManyFiles
| GetManyFilesFail
| GetManyFilesSuccess
| GetFile
| GetFileFail
| GetFileSuccess
| GetManyWorkbookFiles
| GetManyWorkbookFilesFail
| GetManyWorkbookFilesSuccess
| CreateFile
| CreateFileFail
| CreateFileSuccess
| CreateWorkbookFile
| CreateWorkbookFileFail
| CreateWorkbookFileSuccess
| CreateManyFiles
| CreateManyFilesFail
| CreateManyFilesSuccess
| CreateManyWorkbookFiles
| CreateManyWorkbookFilesFail
| CreateManyFilesSuccess
| UpdateWorkbookFile
| UpdateWorkbookFileFail
| UpdateWorkbookFileSuccess
| DeleteFile
| DeleteFileFail
| DeleteFileSuccess
| ClearFile
| ClearFileFail
| ClearFileSuccess
| RenameFile
| RenameFileFail
| RenameFileSuccess


| DeleteWorkbookFile
| DeleteWorkbookFileFail
| DeleteWorkbookFileSuccess
| ReorderWorkbookFilesSuccess;
