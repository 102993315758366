import { NgModule } from '@angular/core';
import { WorkbookBuilderRoutingModule } from './workbook-builder-routing.module';
import { AuthGuard } from '../../../services/auth-guard.service';
import {WorkbookNameModalComponent, DeleteWorkbookDialogComponent, WorkbookBuilderComponent} from './workbook-builder.component';
import { CoreModule } from '../../../core.module';
import { WorkbookBuilderWordsComponent } from './words.component';
import {AlertDialogComponent, WorkbookBuilderSentencesAndMoreComponent} from './sentences-and-more/sentences-and-more.component';
import {WordFilterComponent} from './word-filters/word-filter.component';
import {WordFilterCheckboxComponent} from './word-filters/word-filter-checkbox.component';
import {WordFilterQuickCheckboxComponent} from './word-filters/word-filter-quick-checkbox.component';
import {WordFilterMultipleCheckboxComponent} from './word-filters/word-filter-multiple-checkbox.component';
import {WordFilterAdjustComponent} from './word-filters/word-filter-adjust.component';
import {WordFilterRadioComponent} from './word-filters/word-filter-radio.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {WordsService} from '../../../services/words.service';
import {WordFilterTextareaComponent} from './word-filters/word-filter-textarea.component';
import {WordFilterTextareaLargeComponent} from './word-filters/word-filter-textarea-large.component';
import {WordFilterPairingsComponent} from './word-filters/word-filter-pairings.component';
import {PairingsSectionComponent} from './word-filters/pairings-section.component';
import {WordFilterTilesComponent} from './word-filters/word-filter-tiles.component';
import {TilesService} from '../../../services/word-filter-tiles.service';
import {FlexModule} from '@angular/flex-layout';
import {SettingsService} from '../../../services/new-settings.service';
import {SelectableLetterTilesComponent} from './word-filters/selectable-letter-tiles.component';
import {WordGroupComponent} from './sentences-and-more/word-group.component';
import {PhraseService} from '../../../services/phrase.service';
import {SentenceService} from '../../../services/sentence.service';
import {PassageService} from '../../../services/passage.service';
import {WordGroupCardsComponent, EditItemDialogComponent, DeleteItemDialogComponent, AddItemDialogComponent} from './sentences-and-more/word-group-cards.component';
import {FilesComponent } from './files/files.component';
import {FilesService} from '../../../services/files.service';
import {FileCardsComponent, DeleteFileDialogComponent, EditFileDialogComponent} from './files/file-cards.component';
import { ViewPDFComponent } from './files/view-pdf.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgsContenteditableModule } from '@ng-stack/contenteditable';
import { PdfViewerNewModule } from '../../../../activities/pdf-viewer/pdf-viewer-new/pdf-viewer-new.module';

@NgModule({
    declarations: [
        WorkbookBuilderComponent,
        WorkbookBuilderWordsComponent,
        WorkbookBuilderSentencesAndMoreComponent,
        WordFilterComponent,
        WordFilterCheckboxComponent,
        WordFilterQuickCheckboxComponent,
        WordFilterMultipleCheckboxComponent,
        WordFilterAdjustComponent,
        WordFilterRadioComponent,
        WordFilterTextareaComponent,
        WordFilterTextareaLargeComponent,
        WordFilterPairingsComponent,
        PairingsSectionComponent,
        WordFilterTilesComponent,
        SelectableLetterTilesComponent,
        WordGroupComponent,
        WordGroupCardsComponent,
        EditItemDialogComponent,
        AddItemDialogComponent,
        DeleteItemDialogComponent,
        FilesComponent,
        DeleteFileDialogComponent,
        EditFileDialogComponent,
        FileCardsComponent,
        ViewPDFComponent,
        DeleteWorkbookDialogComponent,
        WorkbookNameModalComponent,
        AlertDialogComponent
    ],
    imports: [
        CoreModule,
        WorkbookBuilderRoutingModule,
        FontAwesomeModule,
        FlexModule,
        NgxSpinnerModule,
        NgsContenteditableModule,
        PdfViewerNewModule
    ],
    exports: [
        SelectableLetterTilesComponent
    ],
    providers: [
        AuthGuard,
        WordsService,
        TilesService,
        SettingsService,
        PhraseService,
        SentenceService,
        PassageService,
        FilesService,
        ViewPDFComponent,
        { provide: MAT_DIALOG_DATA, useValue: [] }
    ]
})

export class WorkbookBuilderModule {}
